import { Navigate, Route, Routes } from 'react-router-dom';
import HotelManagerLogin from './HotelManagerLogin/HotelManagerLogin';

const HotelManagerPublicRoutes = () => {
  return <Routes >
    <Route path='hotel-manager-login' element={<HotelManagerLogin />} />
    <Route path='*' element={<Navigate to="/hotel-manager/hotel-manager-login"/>} />
  </Routes>
};

export default HotelManagerPublicRoutes;