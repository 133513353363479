// IconDescription.js

import React from 'react';

const IconDescription = () => {
  return (
    <section className="icon-description">
      <div className="container">
        <div className="flex">
          <div className="icon-main">
            <div className="icon-box">
              <div className="icon"><img src="/images/discover-daycations.svg" alt="" /></div>
            </div>
            <div className="detail">
              <h6>Discover Daycations</h6>
              <p>Select a date and explore pool, spa, beach access and more, at 1,000+ top hotels.</p>
            </div>
          </div>
          <div className="icon-main">
            <div className="icon-box">
              <div className="icon"><img src="/images/book-confidently.svg" alt="" /></div>
            </div>
            <div className="detail">
              <h6>Book Confidently</h6>
              <p>After booking, receive check-in instructions, parking details, and all necessary information.</p>
            </div>
          </div>
          <div className="icon-main">
            <div className="icon-box">
              <div className="icon"><img src="/images/flexible-support.svg" alt="" /></div>
            </div>
            <div className="detail">
              <h6>Flexible Support and Cancellation</h6>
              <p>Invite guests or cancel bookings as needed with support on our website or app.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IconDescription;
