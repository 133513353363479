// Header.js
import React from 'react';
import Navigation from './Navigation';
import './Header.scss'
import CartQuantity from './CartQuantity';
import LoginDropDown from '../listingPage/LoginDropDown';
import { handleLoginBtnClick } from './headerService';
import { useNavigate } from 'react-router';
import { useGlobalStore } from '../../context/GlobalContext';

function Header() {
  const navigation = useNavigate()
  const { isUserLoggedIn } = useGlobalStore()
  return (
    <header>
      <div className="header">
        <div className="container-fluid">
          <div className="flex">
            <div className="logo">
              <button className="btn-toggle"><img src="/images/desktop-hamburger.svg" alt="" /></button>
              <a onClick={()=> navigation('/')}>RESORTROVER</a>
              <Navigation />
            </div>
            <div className="side-menu">
              <button onClick={() => handleLoginBtnClick()} className="login-btn">{isUserLoggedIn ? 'Logout': 'Login'}
                <img className="login-img" src="/images/arrow-down-white.svg" style={{ color: 'black' }} alt="" />
              </button>
              <a >
                <img src="/images/cart-white.svg" alt="" />
              </a>
              <CartQuantity />
              <LoginDropDown />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
