import React, { useEffect, useState } from 'react'
import { useGlobalStore } from '../../context/GlobalContext'

function CartQuantity() {
  const { cart } = useGlobalStore()
  const [qty, setQty] = useState()
  useEffect(()=>{
    let sum = 0
    for (const key in cart.items) {
      sum += ((cart.items[key].adult || 0) + (cart.items[key].child || 0))
    }
    setQty(sum)
  }, [cart])

  return (
    qty > 0 ? <div className="bg-rp-primary border border-white text-white font-rp-pn-regular h-6 w-6 rounded-full text-center flex justify-center items-center text-13 pt-px z-100 absolute right-0 top-0" id="header-cart-item-count">{qty}</div>: <></>
  )
}

export default CartQuantity