import React from 'react'
import AdminSideBar from '../adminSideBar/AdminSideBar'
import DashboardAppHeader from './DashboardAppHeader'
import './AdminDashboard.scss';

function AdminLayout({ children }) {
  return (
    <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <AdminSideBar />
      <div className="body-wrapper">
        <DashboardAppHeader />
        <div className='pt-4'>
          <div className='pt-4'>
          {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLayout