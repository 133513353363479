import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { api, baseUrl, savedImages } from '../../config';
import { useGlobalStore } from '../../context/GlobalContext';
const Explore = () => {
  const { setLoader } = useGlobalStore()

  const [locations, setLocations] = useState([]);
  useEffect(() => {

    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      setLoader(true)
      const response = await axios.get(`${baseUrl}${api}/countryLocations`);
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
    setLoader(false)
  };

  return (
    <section className="explore">
      <div className="container">
        <span className="line"></span>
        <h2 className="heading2"><b>Explore</b> Locations</h2>
        <div className="panels">
          <div className="flex">
            {(locations || []).map((location, index) => (
              <a key={index}>
                <div className="main">
                  <img src={`${baseUrl}${savedImages}/${(location.images || '').split(',')[0]}`} alt="" />
                  <div className="info">
                    <span>explore</span>
                    <div className="main-title">{location.city}</div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
        {/* <div className="panels">
          <div className="flex">
            <a >
              <div className="main">
                <img src="/images/california@2x.jpeg" alt="" />
                <div className="info">
                  <span>explore</span>
                  <div className="main-title">California</div>
                </div>
              </div>
            </a>
            <a >
              <div className="main">
                <img src="/images/florida@2x.jpeg" alt="" />
                <div className="info">
                  <span>explore</span>
                  <div className="main-title">Florida</div>
                </div>
              </div>
            </a>
            <a >
              <div className="main">
                <img src="/images/Mexico.jpeg" alt="" />
                <div className="info">
                  <span>explore</span>
                  <div className="main-title">Mexico</div>
                </div>
              </div>
            </a>
            <a >
              <div className="main">
                <img src="/images/arizona-new@2x.jpeg" alt="" />
                <div className="info">
                  <span>explore</span>
                  <div className="main-title">Arizona</div>
                </div>
              </div>
            </a>
            <a >
              <div className="main">
                <img src="/images/hawaii@2x.jpeg" alt="" />
                <div className="info">
                  <span>explore</span>
                  <div className="main-title">Hawaii</div>
                </div>
              </div>
            </a>
            <a >
              <div className="main">
                <img src="/images/Caribbean.jpeg" alt="" />
                <div className="info">
                  <span>explore</span>
                  <div className="main-title">Bahamas</div>
                </div>
              </div>
            </a>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default Explore;
