import { Navigate, Route, Routes } from 'react-router-dom';
import AdminLogin from './adminLogin/AdminLogin';

const AdminPublicRoutes = () => {
  return <Routes >
    <Route path='admin-login' element={<AdminLogin />} />
    <Route path='*' element={<Navigate to="/admin/admin-login"/>} />
  </Routes>
};

export default AdminPublicRoutes;