import React, { useEffect, useState } from 'react'
import ListingHeader from '../listingPage/ListingHeader'
import './myBookingPage.scss'
import axios from 'axios'
import { api, baseUrl } from '../../config'
import { State } from 'country-state-city';
import { useGlobalStore } from '../../context/GlobalContext'
import { authConstants } from '../../constants/authConstant'
import Image from '../commons/Img'

const style1 = {
  boxSizing: 'border-box',
  display: 'inline-block',
  overflow: 'hidden',
  width: 'initial',
  height: 'initial',
  background: 'none',
  opacity: 1,
  border: '0px',
  margin: '0px',
  padding: '0px',
  position: 'relative',
  maxWidth: '100%'
}

const style2 = {
  boxSizing: 'border-box',
  display: 'block',
  width: 'initial',
  height: 'initial',
  background: 'none',
  opacity: 1,
  border: '0px',
  margin: '0px',
  padding: '0px',
  maxWidth: '100%',
}

const style3 = {
  boxSizing: 'border-box',
  display: 'inline-block',
  overflow: 'hidden',
  width: 'initial',
  height: 'initial',
  background: 'none',
  opacity: 1,
  border: '0px',
  margin: '0px',
  padding: '0px',
  position: 'absolute',
  inset: '0px'
}

const style4 = {
  position: 'absolute',
  inset: '0px',
  boxSizing: 'border-box',
  padding: '0px',
  border: 'none',
  margin: 'auto',
  display: 'block',
  width: '0px',
  height: '0px',
  minWidth: '100%',
  maxWidth: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  objectFit: 'cover'
}

function MyBookingPage() {
  const { setLoader, getAuthConfig } = useGlobalStore()

  useEffect(() => {
    getBookingList()
  }, [])

  const getBookingList = async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${baseUrl}${api}/bookings`, getAuthConfig(authConstants.userToken))

      setBookingList(res?.data || [])
    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }

  const [bookingList, setBookingList] = useState([]);

  return (<>
    <ListingHeader isSearchVisible={false} isHeaderTransparent={false} margin={false} />
    <div className="bg-rp-light-gray-3 text-rp-primary-black pb-3-5 mb-10 w-full">
      <div className="bg-white border-b py-4 px-22px d-py-7">
        <div className="mx-auto max-w-6xl w-full d-block">
          <div className="font-rp-cera-medium text-2xl leading-30px tracking-extraTight d-text-35 d-leading-10">Keeping it</div>
          <div className="leading-relaxed d-text-17 d-leading-8">$0 credits available</div>
        </div>
      </div>
      <div className="mx-auto max-w-6xl w-full d-flex d-flex-row d-items-start d-pt-5">
        <ul className="side-scroll-list overflow-x-auto bg-white flex flex-col border-b px-22px d-flex-col d-bg-transparent d-border-b-0 d-pl-6 d-py-4 d-w-3-12 d-sticky d-top-20 UserBookings_sideScrollList__iLWwd">
          <li className="flex-none ">
            <button type="button" className="pt-1 pb-1 font-semibold py-4 text-rp-primary-dark border-b-2 border-rp-primary-dark d-text-rp-primary-black d-py-2 d-border-b-0 d-pt-0">My Bookings</button>
          </li>
          <li className="flex-none ml-9 d-ml-0">
            <button type="button" className="pt-1 pb-1 font-semibold py-4 d-py-2 text-rp-primary-black-faded d-pt-0">My Favorites</button>
          </li>
          <li className="flex-none ml-9 d-ml-0">
            <button type="button" className="pt-1 pb-1 font-semibold py-4 d-py-2 text-rp-primary-black-faded d-pt-0">Account Details</button>
          </li>
        </ul>
        <div className="d-w-9-12">
          <ul className="side-scroll-list overflow-x-auto flex flex-row px-5">
            <li className="">
              <button className="bg-white border capitalize px-4 py-11px rounded-lg bg-rp-filter-active border-rp-primary-dark text-rp-primary-dark" type="button">upcoming</button>
            </li>
            <li className="ml-2">
              <button className="bg-white border capitalize px-4 py-11px rounded-lg false" type="button">past</button>
            </li>
            <li className="ml-2">
              <button className="bg-white border capitalize px-4 py-11px rounded-lg false" type="button">cancelled</button>
            </li>
          </ul>
          <section className="py-4">
            <div className="bg-white mx-5 my-4 pt-18px rounded-xl shadow-bookingCard">
              <h3 className="border-b capitalize text-xl leading-30px font-rp-cera-medium pb-4 px-4 d-border-b-0 d-px-5">upcoming Bookings</h3>

              {!!bookingList.length ? <div className="flex-grow w-full relative d:px-8 pt-0 d:pt-10 d:pb-6">
                <ul className="side-scroll-list bg-white w-full flex justify-start flex-col">
                  {bookingList.filter(x => x?.Hotel).map((x, i) => {
                    return <div key={i} role="button" tabIndex="0" className="mb-6 min-w-230 max-w-1024 flex flex-col bg-white outline-none text-rp-primary-black shadow-card rounded-xl flex-row w-full">
                      <div className="px-6 d:w-5/12 cursor-pointer relative min-h-220 w-full flex-shrink-0 d:max-w-429">
                        <a href="/hotels/arizona-biltmore">
                          <span style={style3}>
                            <Image 
                              attr={{
                                title: x.Hotel.name,
                                alt: x.Hotel.name,
                                sizes: "100vw",
                                src: baseUrl + '/savedImages/image_1710272994780_1.jpg',
                                decoding: "async",
                                // dataNimg: "fill",
                                style: style4,
                                className: "rounded-tl-xl rounded-bl-xl rounded-tr-none rounded-br-none lazyloaded"
                              }}
                            />
                          </span>
                        </a>
                      </div>
                      <div className="flex-grow w-full relative d:px-8 pt-0 d:pt-10 d:pb-6 d:w-7/12">
                        <div className="hidden mb-2 d-block text-11 font-rp-pn-semi-bold uppercase text-custom-gray-2 tracking-0.6" data-testid="hotel-city-and-state">{x.Hotel.city}, {State.getStateByCode(x.Hotel.state).name}</div>
                        <h2 className="mb-0 w-full text-19 leading-7 font-rp-pn-semi-bold px-6 d:px-0 d:text-22 d:w-3/4 d:-mt-1 d:mb-10px" id={x.Hotel.name}>{x.Hotel.name}</h2>
                        <div className="overflow-hidden pl-6 d:pl-0 d:overflow-visible relative">
                          <ul className="h-10 w-full flex flex-row justify-start items-center">
                            <div className="relative">
                              <span className="block d-hidden" role="button" tabIndex="0">
                                <li className="self-center h-10 mr-4 w-4 cursor-pointer">
                                  <span style={style1}>
                                    <span style={style2}>
                                      {/* <img src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2716%27%20height=%2740%27/%3e" style={style3} /> */}
                                    </span>
                                    {/* <img data-testid="amenity-waterslide" alt="Waterslide" srcSet="https://static-assets.resortpass.com/assets/www-icons/waterslide.svg 1x, https://static-assets.resortpass.com/assets/www-icons/waterslide.svg 2x" src="https://static-assets.resortpass.com/assets/www-icons/waterslide.svg" decoding="async" data-nimg="intrinsic" className="outline-none" style={style5} /> */}
                                  </span>
                                </li>
                              </span>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  })}
                </ul>
              </div> : <div className="infinite-scroll-component__outerdiv">
                <div className="infinite-scroll-component " style={{ height: 'auto', overflow: 'auto' }}>
                  <div className="capitalize font-rp-pn-regular px-4 py-22px text-sm">No upcoming Bookings</div>
                </div>
              </div>}
            </div>
          </section>
        </div>
      </div>
    </div>
  </>
  )
}

export default MyBookingPage