import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import UserModule from './User/UserModule';
import HotelManagerModule from './HotelManager/HotelManagerModule';
import AdminModule from './Admin/AdminModule';
import { GlobalProvider } from './context/GlobalContext';
import { library } from '@fortawesome/fontawesome-svg-core'

// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import Loader from './shared/Loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <GlobalProvider>
      <BrowserRouter>
        <ToastContainer />
        <Loader />
        <div className="App">
          <Routes>
            <Route path='/admin/*' element={<AdminModule />} />
            <Route path='/hotel-manager/*' element={<HotelManagerModule />} />
            <Route path='/*' element={<UserModule />} />
          </Routes>
        </div>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;

library.add(fab, fas, far)