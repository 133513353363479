export const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const getDayRanges = (days) => {

  // Helper function to get the index of a day in the weekDays array
  const getDayIndex = (day) => weekDays.indexOf(day);

  // Sort the input days array based on the weekDays order
  days.sort((a, b) => getDayIndex(a) - getDayIndex(b));

  let ranges = [];
  let start = days[0];
  let end = start;

  for (let i = 1; i < days.length; i++) {
    const currentDayIndex = getDayIndex(days[i]);
    const previousDayIndex = getDayIndex(days[i - 1]);

    if (currentDayIndex === previousDayIndex + 1) {
      end = days[i];
    } else {
      ranges.push(start === end ? start : `${start} - ${end}`);
      start = days[i];
      end = start;
    }
  }

  ranges.push(start === end ? start : `${start} - ${end}`);
  return ranges.join(" and ");
}