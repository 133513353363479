import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl, api } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalStore } from '../../context/GlobalContext';
import AlertDialog from '../../shared/AlertDialog';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { blue, red } from '@mui/material/colors';
import { dataGridProps } from '../../constants/dataGridProps';

const HotelPasses = ({id}) => {
  const confirmDelete = useRef()
  const { setLoader } = useGlobalStore()
  const navigate = useNavigate();
  const [hotelPasses, sethotelPassesList] = useState([]);

  const getHotelPassesList = async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${baseUrl}${api}/hotelPass?hotelId=${id}`)
      console.log(res)
      sethotelPassesList(res?.data || [])

    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }

  useEffect(() => {
    if(id) {
      getHotelPassesList()
    }
  }, [id])

  const deleteHotelPass = async (id) => {
    try {
      setLoader(true)
      const res = await axios.delete(`${baseUrl}${api}/hotelPass/${id}`)
      if (res.status === 200) {
        sethotelPassesList(hotelPasses.filter(x => x.id !== id))
      }
    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'isChildGuardianMandatory',
      headerName: 'Child Guardian Required',
      valueGetter: (value, row) => row?.isChildGuardianMandatory ? 'Yes' : 'No',
      flex: 1
    },
    {
      field: 'maxPeople',
      headerName: 'Max People',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 100,
      flex: 1,
      getActions: ({row}) => {
        return [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-pen-to-square" color={blue[700]}/>}
            label="Edit"
            onClick={() => navigate(`/hotel-manager/hotel-passes/edit/${row.id}`)}
          />,
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-trash" color={red[700]} />}
            label="Delete"
            onClick={() => confirmDelete.current.handleClickOpen(row)}
          />,
        ]
      }
    },
  ];
  return (
    <div className="card w-100 m-0">
      <div className='card-body'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="card-title fw-semibold pb-1 pt-2">Passes</h5>
          <button type="button" className="btn btn-dark m-1" onClick={() => {
            navigate(`/hotel-manager/hotel-passes/create`)
          }}>
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </button>
        </div>
        <DataGrid
          rows={hotelPasses || []}
          columns={columns}
          {...dataGridProps}
        />
      </div>
      <AlertDialog ref={confirmDelete} action={deleteHotelPass}/>
    </div>
  )
}

export default HotelPasses;