import React from 'react';

function FormContent() {
  return (
    <div className="left-side">
      <form action="">
        <div className="accordion row" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button " style={{
                background: 'transparent',
                color: 'black',
                fontSize: '19px'
              }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Star Ratings
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExamples">
              <div className="accordion-body">
                <div className="">  {/*className is panel here */}
                  <div className="rating-list filter">
                    <div className="list items">
                      <span>5 Stars</span>
                      <div>
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span>4 Stars</span>
                      <div>
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span>3 Stars</span>
                      <div>
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span>Less than 3 Stars</span>
                      <div>
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <img src="/images/star-solid-new.c0617310.svg" alt="" />
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button accordion" style={{
                background: 'transparent',
                color: 'black',
                fontSize: '19px'
              }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                Amenities
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#accordionExamples">
              <div className="accordion-body">
                <div className="">
                  <div className="rating-list filter">
                    <div className="list items">
                      <span><img src="/images/beach.svg" alt="" /> Beach</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span><img src="/images/spa.svg" alt="" /> Spa</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span><img src="/images/hottub.svg" alt="" /> Hot Tub</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span><img src="/images/lazyriver.svg" alt="" /> Lazy River</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span><img src="/images/gym.svg" alt="" /> Gym</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button" style={{
                background: 'transparent',
                color: 'black',
                fontSize: '19px'
              }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseOne">
                Hotel Styles
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse show" data-bs-parent="#accordionExamples">
              <div className="accordion-body">
                <div className="">
                  <div className="rating-list filter">
                    <div className="list items">
                      <span>Beachfront</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span>Luxe</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span>Family Friendly</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span>Adults-Only</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span>Budget-Friendly</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="list items">
                      <span>Waterpark</span>
                      <div>
                        <div className="checkbox-filter">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* <button type="button" className="accordion">Star Ratings </button>
      <div className="panel">
        <div className="rating-list filter">
          <div className="list items">
            <span>5 Stars</span>
            <div>
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span>4 Stars</span>
            <div>
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span>3 Stars</span>
            <div>
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span>Less than 3 Stars</span>
            <div>
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <img src="/images/star-solid-new.c0617310.svg" alt="" />
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <button type="button" className="accordion">Popular Amenities </button>
      <div className="panel">
        <div className="rating-list filter">
          <div className="list items">
            <span><img src="/images/beach.svg" alt="" /> Beach</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span><img src="/images/spa.svg" alt="" /> Spa</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span><img src="/images/hottub.svg" alt="" /> Hot Tub</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span><img src="/images/lazyriver.svg" alt="" /> Lazy River</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span><img src="/images/gym.svg" alt="" /> Gym</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <button type="button" className="accordion">Hotel Styles </button>
      <div className="panel">
        <div className="rating-list filter">
          <div className="list items">
            <span>Beachfront</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span>Luxe</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span>Family Friendly</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span>Adults-Only</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span>Budget-Friendly</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div className="list items">
            <span>Waterpark</span>
            <div>
              <div className="checkbox-filter">
                <input type="checkbox" />
              </div>
            </div>
          </div>
        </div>
      </div> */}

        <div className="btn-clear-main">
          <button type="button" className="btn-clear btn-book">Clear All</button>
        </div>

      </form>

    </div>);
}

export default FormContent;
