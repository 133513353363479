import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import AlertDialog from '../../shared/AlertDialog'
import { useGlobalStore } from '../../context/GlobalContext'
import { red } from '@mui/material/colors'
import { dataGridProps } from '../../constants/dataGridProps'
import axios from 'axios'
import { api, baseUrl } from '../../config'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getDayRanges, weekDays } from '../helper'
import { toast } from 'react-toastify';


const defaultTimings = {
  title: '',
  start: null,
  end: null
}
function HotelTimings({ hotelId }) {
  const confirmDelete = useRef()
  const [error, setError] = useState(null)
  const { setLoader } = useGlobalStore()
  // const days = [
  //   'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
  // ]
  const [tempSelectionStart, setTempSelectionStart] = useState(null);
  const [tempSelectionEnd, setTempSelectionEnd] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [timings, setTimings] = useState([]);
  const [newTimings, setNewTimings] = useState({ ...defaultTimings });

  const columns = [
    {
      field: 'id',
      headerName: 'Step No.',
      flex: 1
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1
    },
    {
      field: '',
      headerName: 'Timing',
      valueGetter: (value, row) => {
        return `${row.start} - ${row.end}`
      },
      flex: 1
    },
    {
      field: 'days',
      headerName: 'Days',
      valueGetter: (value, row) => {
        return getDayRanges(value)
      },
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 100,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-trash" color={red[700]} />}
            label="Delete"
            onClick={(e) => {
              confirmDelete.current.handleClickOpen(row)
            }}
          />,
        ]
      }
    },
  ];

  const fetchTimings = async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${baseUrl}${api}/hotels/timings/${hotelId}`)
      setTimings(res.data || [])
    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }

  useEffect(() => {
    if (hotelId) {
      fetchTimings()
    }
  }, [hotelId])

  const handleSubmit = async () => {
    const formData = {
      ...newTimings, 
      id: timings.length + 1, 
      days: selectedDays, 
      start: newTimings.start.format('hh:mm A'),
      end: newTimings.end.format('hh:mm A')
    }
    if (!formData.title) {
      setError('Please enter a title')
      return
    }
    if (!formData.days.length) {
      setError('Please select days')
      return
    }
    if (!formData.start || !formData.end) {
      setError('Please select timings')
      return
    }
    try {
      setLoader(true)
      const policies = [...timings, formData]
      await axios.post(`${baseUrl}${api}/hotels/timings/${hotelId}`, policies)
      setTimings(policies)
      setNewTimings({ ...defaultTimings })
      setTempSelectionStart(null)
      setTempSelectionEnd(null)
      setSelectedDays([])
      toast.success('New timing added successfully!')
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message)
    } finally {
      setLoader(false)
    }
  }

  const deleteTiming = async (id) => {
    try {
      setLoader(true)
      const policies = timings.filter(x => x.id !== id).map((x, i) => { return { ...x, id: i + 1 } })
      const res = await axios.post(`${baseUrl}${api}/hotels/timings/${hotelId}`, policies)
      if (res.status === 200) {
        setTimings(policies)
      }
      toast.success('New timing removed successfully!')
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message)
    } finally {
      setLoader(false)
    }
  }

  const tempSelection = useMemo(() => {
    const startIndex = weekDays.findIndex(x => x === tempSelectionStart)
    const endIndex = weekDays.findIndex(x => x === tempSelectionEnd)
    let currSelection = []
    if (!tempSelectionStart && selectedDays.length) {
      return currSelection
    }
    if (startIndex === endIndex) {
      currSelection = [tempSelectionStart]
      return currSelection
    }
    if (startIndex < endIndex) {
      currSelection = weekDays.slice(startIndex, endIndex + 1)
      return currSelection
    }

    currSelection = [...weekDays.slice(0, endIndex + 1), ...weekDays.slice(startIndex, weekDays.length)]
    return currSelection
  }, [tempSelectionStart, tempSelectionEnd])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="card w-100 m-0">
        <div className='card-body'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 className="card-title fw-semibold pb-1 pt-2">Operational Hours</h5>
            {error && <div className="alert alert-danger">{error}</div>} {/* Display error message if error exists */}
          </div>
          <h6>Add new timings</h6>
          <form className='mb-3'>
            <div className="mb-3">
              <label className="form-label">Title</label>
              <input type="text" className="form-control" value={newTimings.title} onChange={(e) => {
                setNewTimings({ ...newTimings, title: e.target.value })
              }} />
            </div>
            <div style={{ display: 'grid' }} className="mb-3">
              <label className="form-label">Start Time</label>
              <TimePicker label="" value={newTimings.start} onChange={(e) => setNewTimings({ ...newTimings, start: e })} />
            </div>
            <div style={{ display: 'grid' }} className="mb-3">
              <label className="form-label">End Time</label>
              <TimePicker label="" value={newTimings.end} onChange={(e) => setNewTimings({ ...newTimings, end: e })} />
            </div>
            <div style={{ display: 'grid' }} className="mb-3">
              <label className="form-label">Day Range</label>
              <div>
                {(weekDays || []).map((x, i) => {
                  return <button key={i} className={`${selectedDays.includes(x) ? 'active' : ''} ${tempSelection.includes(x) ? 'partial-active' : ''} selectable-icon m-1 p-1`}
                    onMouseEnter={() => {
                      if (!tempSelectionStart) {
                        return
                      }
                      setTempSelectionEnd(x)
                    }}
                    type='button'
                    onClick={() => {
                      if (!tempSelectionStart) {
                        setSelectedDays([])
                        setTempSelectionStart(x)
                        return
                      }

                      setSelectedDays(tempSelection)
                      setTempSelectionStart(null)
                    }}>
                    {x}
                  </button>
                })}
              </div>
            </div>
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add</button>
          </form>
          <DataGrid
            rows={timings || []}
            columns={columns}
            {...dataGridProps}
          />
        </div>
        <AlertDialog ref={confirmDelete} action={deleteTiming} />
      </div>
    </LocalizationProvider>
  )
}

export default HotelTimings