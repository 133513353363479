import React, { Fragment, useEffect, useState } from 'react'
import Pass from './Pass'
import { useGlobalStore } from '../../context/GlobalContext';
import { useParams } from 'react-router';
import { getPrice } from '../commons/helper';
import { toast } from 'react-toastify'

function DetailPassRightPanel({ data }) {
  const { id } = useParams()
  const { setCart, cart, availablePasses } = useGlobalStore()
  const { passes, maxPeople, hotelPassId, hotelName } = data;
  const [localCart, setLocalCart] = useState({ total: 0, hotelPassId, hotelName })
  const [error, setError] = useState(null)
  useEffect(() => {
    for (const pass of passes) {
      localCart[pass.passType] = 0
    }
    setLocalCart({ ...localCart })
  }, [])

  const addToCart = () => {
    if (!cart.date) {
      setError('Date not selected')
      const select = document.getElementsByClassName("date-select")?.[0];
      if (select) {
        select.classList.add('active')
        select.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return
    }
    const orginalCart = JSON.parse(JSON.stringify(cart))
    if (!!cart.items[hotelPassId]) {
      cart.items[hotelPassId].child += localCart.child || 0
      cart.items[hotelPassId].adult += localCart.adult || 0
    } else {
      cart.items[hotelPassId] = { hotelName, hotelId: id }
      cart.items[hotelPassId].child = localCart.child || 0
      cart.items[hotelPassId].adult = localCart.adult || 0
      cart.items[hotelPassId].adultPrice = getPrice(localCart.adultPrice) || 0
      cart.items[hotelPassId].childPrice = getPrice(localCart.childPrice) || 0
    }
    if (maxPeople < (cart.items[hotelPassId].adult + cart.items[hotelPassId].child + (Number(availablePasses.find(x => x.hotelPassId === hotelPassId)?.bookedAmount || 0)))) {
      setCart({ ...orginalCart })
      toast.error('Not enough available passes')
      return
    }
    cart.subTotal += getPrice(localCart.total)
    setCart({ ...cart })
    const newLocalCart = { total: 0, hotelPassId, hotelName }
    for (const pass of passes) {
      newLocalCart[pass.passType] = 0
    }
    setLocalCart({ ...newLocalCart })
  }

  const increament = (passType) => {
    setLocalCart((prev) => {
      const price = getPrice(passes.find(x => x.passType == passType).price)
      return { ...prev, total: getPrice(prev.total) + getPrice(price), [passType]: localCart[passType] + 1, [passType + 'Price']: getPrice(price) }
    })
  }

  const decrement = (passType) => {
    if (localCart[passType] == 0) {
      return
    }
    setLocalCart((prev) => {
      const price = getPrice(passes.find(x => x.passType == passType).price)
      return { ...prev, total: getPrice(prev.total) - getPrice(price), [passType]: localCart[passType] - 1 }
    })
  }

  const displayPeopleLeft = () => {
    const bookedAmount = (availablePasses || []).find(x => x?.bookedAmount)?.bookedAmount || 0
    return maxPeople - bookedAmount
  }

  return (<div className="dt-pro-right">
    <div className="details flex-column">
      <div className="enod">
        Only {displayPeopleLeft()} left
      </div>
      <div className='passes-block'>
        {
          (passes || []).map((x, i) => {
            return (<Pass pass={x} key={i} />)
          })
        }
      </div>
    </div>
    <button className="btn-select-option" onClick={(e) => {
      e.target.parentElement.children.item(2).classList.toggle('d-block')
    }}>Select</button>
    <div className="dropdown-cs-select-detail">
      <div className="info">
        {
          (passes || []).map((x, i) => {

            if (x.passType === 'infant' && x.price == 0) {
              return <Fragment key={i}></Fragment>
            }
            return <div className={`ac-class ${x.passType}`} key={i}>
              <div className="type" >
                <div className="title text-capitalize">{x.passType == 'general' ? 'adult' : x.passType}</div>
                {x.passType === 'adult' && <span>over 13</span>}
                {x.passType === 'child' && <span>age 2 - 12</span>}
                {x.passType === 'infant' && <span>below 2</span>}
              </div>
              <div className="counter">
                <button className="decrement" onClick={() => decrement(x.passType)}>-</button>
                <div className="totalNum">{localCart[x.passType]}</div>
                <button className="increment" onClick={() => increament(x.passType)}>+</button>
              </div>
            </div>
          })
        }
      </div>
      <div className="totalOfInfo">
        {error && <div className="text-danger">{error}</div>}
        <div className="totaldiv">
          <div>total:</div>
          <div>$<span className="total">{getPrice(localCart.total)}</span> USD</div>
        </div>
        <div className="addToCart">
          <button onClick={() => addToCart(localCart)}>Add to Cart</button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default DetailPassRightPanel