import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { api, baseUrl } from '../../config';
import './AmenitiesForm.scss'
import { useGlobalStore } from '../../context/GlobalContext';
import { authConstants } from '../../constants/authConstant';
import { AmenityIcons } from '../../shared/AmenityIcons';

function AmenitiesForm() {
  const { setLoader, getAuthConfig } = useGlobalStore()
  const { id } = useParams();
  const [form, setForm] = useState({
    name: '',
    icon: '',
  })
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    if (id) {
      setLoader(true)
      const response = await axios.get(`${baseUrl}${api}/admin/amenities/${id}`)
      setForm({
        name: response.data.name,
        icon: AmenityIcons.find(x=>x.value === response.data.icon)?.value,
      })
      setLoader(false)
    }
  }
  useEffect(() => {
    getData()
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      id: id ?? undefined,
      name: form.name,
      icon: form.icon
    };
    try {
      setLoader(true)
      if (id) {
        await axios.put(`${baseUrl}${api}/admin/amenities/${id}`, formData)
      } else {
        await axios.post(`${baseUrl}${api}/admin/amenities`, formData, getAuthConfig(authConstants.adminToken))
      }
    } catch (e) {
      setError('Invalid form values. Please try again.');
    }
    setLoader(false)
    navigate(`/admin/amenities`);
  };
  return (
    <div>
      <div className="card m-0">
        <div className="card-body">
          <h5 className="card-title fw-semibold mb-4">Add Amenities </h5>
          {!!error && <div className="alert alert-danger">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input type="text" className="form-control" value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} />
            </div>
            <div className="mb-3">
              <label className="form-label">Icon</label>
              <div>
                {(AmenityIcons || []).map((x, i) => {
                  return <button key={i} className={x.value === form.icon ? 'active selectable-icon m-1 p-1' : 'selectable-icon m-1 p-1'} type='button' onClick={() => {
                    setForm({ ...form, icon: x.value })
                  }}>
                    {x.icon}
                  </button>
                })}
              </div>
            </div>
            <button type="submit" className="btn btn-primary" style={{ float: 'right' }}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AmenitiesForm
