import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../commons/AdminDashboard.scss';
import axios from 'axios';
import { Country, State } from 'country-state-city';
import { baseUrl, api } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalStore } from '../../context/GlobalContext';
import { toast } from 'react-toastify';
import AlertDialog from '../../shared/AlertDialog';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { dataGridProps } from '../../constants/dataGridProps';
import { blue, green, red } from '@mui/material/colors';
import { authConstants } from '../../constants/authConstant';

function HotelManagers() {
  const confirmDelete = useRef()
  const { setLoader, getAuthConfig } = useGlobalStore()

  const navigate = useNavigate();
  const [hotelManagerList, setHotelManagerList] = useState([]);

  const getHotelManagerList = async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${baseUrl}${api}/admin/hotel-managers`, getAuthConfig(authConstants.adminToken))
      setHotelManagerList((res?.data || []).map(x => {
        return {
          ...x,
          country: Country.getCountryByCode(x?.country || '')?.name,
          state: State.getStateByCode(x?.state || '')?.name
        }
      }))
    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }

  useEffect(() => {
    getHotelManagerList()
  }, [])

  const deleteHotelManager = async (id) => {
    try {
      setLoader(true)
      const res = await axios.delete(`${baseUrl}${api}/admin/hotel-managers/${id}`)
      if (res.status === 200) {
        setHotelManagerList(hotelManagerList.filter(x => x.id !== id))
        toast.success("Hotel Manager deleted successfully!")
      
      }
    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }

  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 100,
      flex: 1,
      getActions: ({row}) => {
        return [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-regular fa-rectangle-list" color={green[700]} title='View Bookings'/>}
            label="View Booking"
            onClick={() => navigate(`/admin/hotel-bookings/${row.id}`)}
          />,
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-pen-to-square" color={blue[700]} title='Edit'/>}
            label="Edit"
            onClick={() => navigate(`/admin/hotel-managers/edit/${row.id}`)}
          />,
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-trash" color={red[700]} title='Delete' />}
            label="Delete"
            onClick={() => confirmDelete.current.handleClickOpen(row)}
          />,
        ]
      }
    },
  ];
  return (
    <div className="card w-100 m-0">
      <div className="card-body">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="card-title fw-semibold pb-1 pt-2">Hotel Managers</h5>
          <button type="button" className="btn btn-dark m-1" onClick={() => {
              navigate('/admin/hotel-managers/create')
            }}>
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </button>
        </div>
        <DataGrid
          rows={hotelManagerList || []}
          columns={columns}
          {...dataGridProps}
        />
      </div>
      <AlertDialog ref={confirmDelete} action={deleteHotelManager}/>
    </div>
  );
}

export default HotelManagers