import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import '../HotelManagerDashboard/HotelManagerDashboard.scss';
import { baseUrl, api } from '../../config'
import { useGlobalStore } from '../../context/GlobalContext';
import AlertDialog from '../../shared/AlertDialog';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { dataGridProps } from '../../constants/dataGridProps';
import { green, red } from '@mui/material/colors';
import { authConstants } from '../../constants/authConstant';
import { AmenityIcons } from '../../shared/AmenityIcons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { toast } from 'react-toastify';

function HotelAmenities({ hotelId }) {
  const confirmDelete = useRef()
  const { setLoader, getAuthConfig } = useGlobalStore()
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [assignedAmenitiesList, setAssignedAmenitiesList] = useState([]);
  const getAmenitiesList = async () => {
    try {
      const res = await axios.get(`${baseUrl}${api}/amenities`, getAuthConfig(authConstants.hotelManagerToken))
      setAmenitiesList((res?.data || []))
    } catch (e) {
      console.error(e)
    }
  }

  const deleteAmenities = async (amenityId) => {
    setLoader(true)
    try {
      await axios.post(`${baseUrl}${api}/amenities/unassign/${hotelId}/${amenityId}`, null, getAuthConfig(authConstants.hotelManagerToken))
      getAssignedAmenities()
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message)
    } finally {
      setLoader(false)
    }
  }

  const getAssignedAmenities = async () => {
    try {
      const res = await axios.get(`${baseUrl}${api}/amenities/assigned/${hotelId}`, getAuthConfig(authConstants.hotelManagerToken))
      setAssignedAmenitiesList((res?.data || []))
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message)
    } finally {
      setLoader(false)
    }
  }

  const getData = async () => {
    setLoader(true)
    await getAssignedAmenities(hotelId)
    await getAmenitiesList()
    setLoader(false)
  }

  useEffect(() => {
    if (hotelId) {
      getData()
    }
  }, [hotelId])

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'icon',
      headerName: 'Icon',
      flex: 1,
      renderCell: ({ value }) => AmenityIcons.find(x => x.value === value || '')?.icon || []
    },
  ];

  const setAssignedAmenities = async (amenityId) => {
    setLoader(true)
    try {
      await axios.post(`${baseUrl}${api}/amenities/assign/${hotelId}/${amenityId}`, null, getAuthConfig(authConstants.hotelManagerToken))
      getAssignedAmenities()
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message)
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="card w-100 m-0">
      <div className='card-body'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="card-title fw-semibold pb-1 pt-2">Hotel Amenities</h5>
        </div>
        <h6 className="card-title fw-semibold pb-1 pt-2">Assigned Amenities</h6>
        <DataGrid
          rows={assignedAmenitiesList || []}
          columns={[...columns, {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 100,
            flex: 1,
            getActions: ({ row }) => {
              return [
                <GridActionsCellItem
                  icon={<RemoveCircleOutlineIcon />}
                  label=""
                  color={red[700]}
                  onClick={() => confirmDelete.current.handleClickOpen(row)}
                />,
              ]
            }
          },]}
          {...dataGridProps}
        />
        <br />
        <h6 className="card-title fw-semibold pb-1 pt-2">All Amenities</h6>
        <DataGrid
          rows={amenitiesList || []}
          columns={[...columns, {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 100,
            flex: 1,
            getActions: ({ row }) => {
              return [
                <GridActionsCellItem
                  color={green[700]}
                  icon={<AddCircleOutlineIcon />}
                  label=""
                  onClick={() => setAssignedAmenities(row.id)}
                />,
              ]
            }
          },]}
          {...dataGridProps}
        />
      </div>
      <AlertDialog ref={confirmDelete} action={deleteAmenities} />
    </div>
  )
}

export default HotelAmenities



