// Header.js
import React from 'react';
import ListingHeader from './ListingHeader';
import ListingSection from './ListingSection';
import Header from '../commons/Header';


function ListingPage() {
  return (<>
    <ListingHeader isSearchVisible={false} isHeaderTransparent={false} />
    <ListingSection/>
  </>
  );
}

export default ListingPage;
