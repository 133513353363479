// HotelManagerLogin.js
import React, { useState } from 'react';
import axios from 'axios';
import { useGlobalStore } from '../../context/GlobalContext';
import './HotelManagerLogin.scss'
import { baseUrl, api } from '../../config';

const HotelManagerLogin = () => {
  const [formData, setFormData] = useState({
    email: 'john@example.com',
    password: 'your_password_here'
  });
  const [error, setError] = useState(null); // State for storing error message
  const { setIsHotelManagerLoggedIn } = useGlobalStore(); // State for storing error message

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${baseUrl}${api}/hotel-manager-login`, formData)
      .then(response => {
        localStorage.setItem('hotelManagerToken', response.data.token);
        setIsHotelManagerLoggedIn(true)
      })
      .catch(error => {
        // Handle login error
        setError('Invalid email or password. Please try again.'); // Set error message
      });
  };

  return (
    <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <div className="card-body">
                  <p className="text-center">Hotel Manager</p>
                  {error && <div className="alert alert-danger">{error}</div>} {/* Display error message if error exists */}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <button type="submit" className="btn btn-primary w-100 py-2 mb-4 rounded-2">Sign In</button>
                    <div className="d-flex align-items-center justify-content-center">
                      {/* <a className="text-primary fw-bold ms-2" href="./authentication-register.html">Create an account</a> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelManagerLogin;
