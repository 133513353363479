import { Navigate, Route, Routes } from 'react-router-dom';
import HotelForm from './HotelForm/HotelForm';
import HotelPasses from './HotelPasses/HotelPasses';
import HotelPassForm from './HotelPassForm/HotelPassForm';
import HotelCategories from './HotelCategories/HotelCategoires';
import HotelCategoriesForm from './HotelCategoriesForm.jsx/HotelCategoriesForm';
import HotelBookings from './HotelBookings/HotelBookings';
import HotelAmenities from './HotelAmenities/HotelAmenities';
import { useEffect } from 'react';
import axios from 'axios';
import { api, baseUrl } from '../config';
import { useGlobalStore } from '../context/GlobalContext';
import { authConstants } from '../constants/authConstant';
import HotelCancelPolicy from './HotelCancelPolicy/HotelCancelPolicy';
import HotelHowItWorks from './HotelHowItWorks/HotelHowItWorks';
import HotelTimings from './HotelTimings/HotelTimings';

const HotelManagerPrivateRoutes = () => {
  const { setLoader, getAuthConfig, setMyHotel, myHotel } = useGlobalStore()
  useEffect(() => {
    setLoader(true)
    axios.get(`${baseUrl}${api}/hotels/assigned`, getAuthConfig(authConstants.hotelManagerToken))
    .then(({data}) => {
      setMyHotel(data)
      setLoader(false)
    }).catch(() => {})
  }, [])
  return <Routes>
    {/* <Route path='' element={<HotelManagerDashboard />} /> */}
    <Route path='/profile' element={<HotelForm id={myHotel?.id} />} />
    {/* <Route path='/hotel/edit/:id' element={<HotelForm />} /> */}

    <Route path='/hotel-passes/create' element={<HotelPassForm hotelId={myHotel?.id} />} />
    <Route path='/hotel-passes/edit/:hotelPassId' element={<HotelPassForm hotelId={myHotel?.id} />} />
    <Route path='/hotel-passes' element={<HotelPasses id={myHotel?.id} />} />

    <Route path='/hotel-categories' element={<HotelCategories />} />
    <Route path='/hotel-category/create' element={<HotelCategoriesForm />} />
    <Route path='/hotel-category/edit/:id' element={<HotelCategoriesForm />} />

    <Route path='/hotel-bookings' element={<HotelBookings hotelId={myHotel?.id} />} />

    <Route path='/cancel-policy' element={<HotelCancelPolicy hotelId={myHotel?.id} />} />
    <Route path='/how-it-works' element={<HotelHowItWorks hotelId={myHotel?.id} />} />

    <Route path='/timings' element={<HotelTimings hotelId={myHotel?.id} />} />
    <Route path='/timings/create' element={<HotelTimings hotelId={myHotel?.id} />} />
    <Route path='/timings/edit/:id' element={<HotelTimings hotelId={myHotel?.id} />} />
    
    <Route path='/hotel-amenities' element={<HotelAmenities hotelId={myHotel?.id} />} />
    {/* <Route path='/hotel-amenities/create' element={<HotelAmenitiesForm />} />
    <Route path='/hotel-amenities/edit/:id' element={<HotelAmenitiesForm />} /> */}
    <Route path='*' element={<Navigate to="/hotel-manager/profile" />} />
  </Routes>
};

export default HotelManagerPrivateRoutes;