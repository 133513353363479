import React from 'react'
import { useGlobalStore } from '../../context/GlobalContext'
import './Cart.scss'
import moment from 'moment/moment'
import axios from 'axios'
import { api, baseUrl } from '../../config'
import { getPrice } from '../commons/helper'
function Cart({ hotelName }) {
  const { cart, setCart } = useGlobalStore()
  const isEmptyCart = !Object.keys(cart.items).length
  const formattedDate = cart.date ? moment(cart.date).format('MMMM DD, yyyy') : ''
  console.log(formattedDate)
  const getItemRows = () => {
    const rows = [];
    for (let i in cart.items) {
      let types = ['adult', 'child']
      for (const type of types) {
        for (let index = 0; index < cart.items[i][type]; index++) {
          rows.push(<div className="flex justify-between items-center h-auto py-3 border-t border-rp-gray-divider pb-2 pt-2" key={i + index + type}>
            <p>{cart.items[i].hotelName}  - {type} (<span>
              <span className="text-15">${getPrice(cart.items[i][type + 'Price'])}<span className="text-15"> USD</span>
              </span>
            </span>)
            </p>
            <button type="button" onClick={() => {
              cart.items[i][type] = cart.items[i][type] - 1
              cart.subTotal = getPrice(cart.subTotal) - getPrice(cart.items[i][type + 'Price'])
              setCart({ ...cart, items: { ...cart.items } })
            }}
              className="rounded-full h-5 w-5 flex-shrink-0 relative border border-rp-gray-divider cursor-pointer"
              style={{ background: 'transparent', width: 20, height: 20 }}>
              <div className="h-3 w-3 mx-auto flex justify-center items-center p-0">
                <span className="cart-block-1">
                  <img alt="close" src={'/images/close-black-light.svg'} width={20} height={20} className="cart-close-btn-img" />
                </span>
              </div>
            </button>
          </div>);
        }
      }
    }
    return rows
  }
  
  return (
    <div className="dt-cart-section top-120-fixed sticky-top ">
      <h3 className="heading3">Your Cart</h3>
      <div className="dt-cart-info">
        <div className="flex pb-2 pt-2">
          <div className="dt-ci">
            <div className="name">{hotelName}</div>
            <div className="date">{formattedDate}</div>
          </div>
          <div className="image">
            <img src="/images/details_hiltonwaikoloa_poolaerial.jpg" alt="" />
          </div>
        </div>
        {!isEmptyCart && <div className="cart-items-section">
          <div>
            {getItemRows()}
          </div>
          <div>
            {/* <div className="flex justify-between items-center h-auto py-3 border-t border-rp-gray-divider">
              <p>DayDream Spa Pass  - adult (<span>
                <span className="text-15">$95<span className="text-15"> USD</span>
                </span>
              </span>)
              </p>
              <button type="button" className="rounded-full h-5 w-5 flex-shrink-0 relative border border-rp-gray-divider cursor-pointer" style={{ background: 'transparent', width: 20, height: 20 }}>
                <div className="h-3 w-3 mx-auto flex justify-center items-center p-0">
                  <span className="cart-block-1">
                    <img alt="close" src={'/images/close-black-light.svg'} width={20} height={20} className="cart-close-btn-img" />
                  </span>
                </div>
              </button>
            </div>
            <div className="flex justify-between items-center h-auto py-3 border-t border-rp-gray-divider">
              <p>DayDream Spa Pass  - adult (<span>
                <span className="text-15">$95<span className="text-15"> USD</span>
                </span>
              </span>)
              </p>
              <button type="button" className="rounded-full h-5 w-5 flex-shrink-0 relative border border-rp-gray-divider cursor-pointer" style={{ background: 'transparent', width: 20, height: 20 }}>
                <div className="h-3 w-3 mx-auto flex justify-center items-center p-0">
                  <span className="cart-block-1">
                    <img alt="close" src={'/images/close-black-light.svg'} width={20} height={20} className="cart-close-btn-img" />
                  </span>
                </div>
              </button>
            </div> */}
          </div>
        </div>}
        {
          isEmptyCart && (<div className="cart-items-section">
            Please select a product to continue
          </div>)
        }
        <div className="cart-price-section">
          <span>
            <strong>Subtotal:</strong>
          </span>
          <span>
            <strong>${getPrice(cart.subTotal)}</strong> USD</span>
        </div>
        <div className="h-12 d:mb-2">
          {!!cart.subTotal && <button type="button" className="book-now-btn" onClick={(e) => {
            
            axios.post(`${baseUrl}${api}/bookings`, cart).then(()=>{
              setCart({
                subTotal: 0,
                items: {},
                date: null
              })
            }).catch((e) => {
              console.log(e)
            })
          }}>Book Now</button>}
        </div>
      </div>
    </div>
  )
}

export default Cart