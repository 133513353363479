/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useGlobalStore } from '../../context/GlobalContext'
import LogoutIcon from '@mui/icons-material/Logout';

function DashboardAppHeader() {
  const { setIsHotelManagerLoggedIn, setHotelManagerClear } = useGlobalStore()
  return (
    <header className="app-header">
    <nav className="navbar navbar-expand-lg navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item d-block d-xl-none">
          <a className="nav-link sidebartoggler nav-icon-hover" id="headerCollapse">
            <i className="ti ti-menu-2"></i>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link nav-icon-hover">
            <i className="ti ti-bell-ringing"></i>
            <div className="notification bg-primary rounded-circle"></div>
          </a>
        </li>
      </ul>
      <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
        <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
          <li className="nav-item">
            <a className="nav-link nav-icon-hover" onClick={() => {
              setIsHotelManagerLoggedIn(false)
              setHotelManagerClear()
            }}>
              <span>
                <LogoutIcon />
              </span>
              <span className="hide-menu">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  )
}

export default DashboardAppHeader