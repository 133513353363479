/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from 'react-router'
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import BallotIcon from '@mui/icons-material/Ballot';

function HotelManagerSideBar() {
  const navigate = useNavigate()
  return (
    <aside className="left-sidebar">
      <div>
        <nav className="sidebar-nav scroll-sidebar" style={{height: '100vh'}} data-simplebar="">
          <ul id="sidebarnav">
            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span className="hide-menu">Admin</span>
            </li>
            <li className="sidebar-item">
              <a className="sidebar-link" href="./index.html" aria-expanded="false">
                <span>
                  <HomeIcon />
                </span>
                <span className="hide-menu">Dashboard</span>
              </a>
            </li>
            <li className="sidebar-item">
              <a className="sidebar-link" onClick={()=> navigate('/admin/hotel')}  aria-expanded="false">
                <span>
                  <ApartmentIcon />
                </span>
                <span className="hide-menu">Hotels</span>
              </a>
            </li>
            <li className="sidebar-item">
              <a className="sidebar-link" onClick={()=>navigate('/admin/hotel-managers')}  aria-expanded="false">
                <span>
                  <ManageAccountsIcon />
                </span>
                <span className="hide-menu">Hotel Managers</span>
              </a>
            </li>
            <li className="sidebar-item">
              <a className="sidebar-link" onClick={()=>navigate('/admin/users')}  aria-expanded="false">
                <span>
                  <PersonIcon />
                </span>
                <span className="hide-menu">Users</span>
              </a>
            </li>
            <li className="sidebar-item">
              <a className="sidebar-link" onClick={()=>navigate('/admin/amenities')} aria-expanded="false">
                <span>
                  <BallotIcon />
                </span>
                <span className="hide-menu">Amenities</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  )
}

export default HotelManagerSideBar