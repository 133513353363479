import React from 'react'
import './loader.scss'
import { useGlobalStore } from '../context/GlobalContext';

function Loader() {
  const { loader } = useGlobalStore();
  return (
    loader && <div className='loader-wrapper'>
      <div className="loader">
      </div>
    </div>
  )
}

export default Loader