// Banner.js
import React, {useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { api, baseUrl } from '../../config';
import { useGlobalStore } from '../../context/GlobalContext';
function Banner() {
  const { setLoader } = useGlobalStore()
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState([]);

  // Handler for input change
  const handleInputChange = async (newValue) => {
    setSearchValue(newValue);
    try {
      setLoader(true)
      const response = await axios.get(`${baseUrl}${api}/hotels/search-suggestions?query=${newValue}`);
      setOptions(response.data.hotels);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
    setLoader(false)
  };

  // Handler for form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`/listing?search=${searchValue}`);
  };
  const handleOptionClick = (value) => {
    setSearchValue(value);
    setOptions([]); 
  };
  return (
    <section className="banner">
      <div className="main-banner">
        <div className="rel">
          <img src="/images/landing-page-video.gif" style={{ width: '100%', height: '800px', objectFit: 'cover' }} />
        </div>
        <div className="banner-description">
          <div className="container">
            <h4>Take a daycation</h4>
            <p>Experience the world’s best hotels for the day, no overnight stay required.</p>
            
            <div className="search-form">
              
            <form onSubmit={handleSubmit}>
                <div className="banner-form">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><img src="/images/location-pin-new.svg" alt="" /></span>
                    </div>
                    <input
          type="text"
          className="form-control"
          placeholder="Search City, State, or Hotel"
          value={searchValue}
          onChange={(e) => handleInputChange(e.target.value)}
        />
        {(options || []).length > 0 && (
          <ul className="autocomplete-options" style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1000,background: 'white',
          width: '100%',
          border: "1px solid transparent",
          'border-radius': '10px' }}>
            {(options || []).map((option, index) => (
              <li key={index} style={{margin:'10px'}} onClick={() => handleOptionClick(option.name)}>
                {option.city},{option.state} {option.name}
              </li>
            ))}
          </ul>
        )}
                    {/* <select className="form-control"  value={searchValue}
                   onChange={(e) => handleInputChange(e.target.value)}
              >
              {
                dummyData.map((option, index) => {
                  return <option key={index} onClick={() => setSearchValue(option.name)} label={option.name} value={ option.name} />
                })
              }
            </select>
                    <input type="text" className="form-control" placeholder="City, State or Hotel" aria-label="city" aria-describedby="basic-addon1" />
                     */}
                  </div>
                </div>
                <div className="banner-form">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><img src="/images/calendar-header-icon-new.svg" alt="" /></span>
                    </div>
                    <input type="date" className="form-control" placeholder="Select A Date" aria-label="city" aria-describedby="basic-addon1" />
                  </div>
                </div>
                <div className="banner-form">
                  <input type="submit" value="Submit" className="btn btn-primary" />
                </div>
              </form> 
                        {/* <form onSubmit={handleSubmit}>
                      
              <div className="banner-form dropdown">
                <input
                  type="text"
                  className="form-control  dropdown-toggle"
                  placeholder="Search City, State, or Hotel"
                  value={searchValue}
                  onChange={(e) => handleInputChange(e.target.value)}
                />

                <ul class="dropdown-menu">
              {dummyData.map((option, index) => (
                <li key={index} onClick={() => setSearchValue(option.name)}>
                  {option.name}
                </li>
              ))}
            </ul>
              </div>
              <div className="banner-form">
                <input type="submit" value="Search" className="btn btn-primary" />
              </div>
            </form> */}
              {/* <form action="">
                <div className="banner-form">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><img src="/images/location-pin-new.svg" alt="" /></span>
                    </div>
                    <input type="text" className="form-control" placeholder="City, State or Hotel" aria-label="city" aria-describedby="basic-addon1" />
                  </div>
                </div>
                <div className="banner-form">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><img src="/images/calendar-header-icon-new.svg" alt="" /></span>
                    </div>
                    <input type="date" className="form-control" placeholder="Select A Date" aria-label="city" aria-describedby="basic-addon1" />
                  </div>
                </div>
                <div className="banner-form">
                  <input type="submit" value="Submit" className="btn btn-primary" />
                </div>
              </form> */}
            </div>
       
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
