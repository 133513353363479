import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { forwardRef, useImperativeHandle, useState } from 'react';

const AlertDialog = forwardRef(({ action, message="Do you want to delete" }, ref) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);

  useImperativeHandle(ref, () => ({
    handleClickOpen: (selectedItem) => {
      setItem(selectedItem);
      setOpen(true);
    }
  }), []);

  const handleClose = () => {
    setOpen(false);
  };

  const executeAction = () => {
    action(item.id);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Delete
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message} {item?.name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="success" onClick={handleClose}>No</Button>
        <Button variant="contained" color="error" onClick={executeAction} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default AlertDialog;
