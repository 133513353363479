import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { api, baseUrl } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalStore } from '../../context/GlobalContext';
import AlertDialog from '../../shared/AlertDialog';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { blue, red } from '@mui/material/colors';
import { dataGridProps } from '../../constants/dataGridProps';


function HotelCategories(){
  const confirmDelete = useRef()
  const { setLoader } = useGlobalStore()
    
  const [HotelCategories, setHotelCategoriesList] = useState([]);
  const navigate = useNavigate();
  
    const getHotelCategoriesList = async () => {
      setLoader(true)
      try {
        const res = await axios.get(`${baseUrl}${api}/hotelCategories`)
        setHotelCategoriesList(res?.data || [])
  
      } catch (e) {
        console.error(e)
      }
      setLoader(false)
    }
  
    useEffect(() => {
      getHotelCategoriesList()
    }, [])
  
    const deleteHotelCategories = async (id) => {
      try {
        setLoader(true)
        const res = await axios.delete(`${baseUrl}${api}/hotelCategories/${id}`)
        if (res.status === 200) {
          setHotelCategoriesList(HotelCategories.filter(x => x.id !== id))
        }
      } catch (e) {
        console.error(e)
      }
      setLoader(false)
    }
    
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 100,
      flex: 1,
      getActions: ({row}) => {
        return [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-pen-to-square" color={blue[700]}/>}
            label="Edit"
            onClick={() => navigate(`/hotel-manager/hotel-category/edit/${row.id}`)}
          />,
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-trash" color={red[700]} />}
            label="Delete"
            onClick={() => confirmDelete.current.handleClickOpen(row)}
          />,
        ]
      }
    },
  ];
    return (
      <div className="card w-100 m-0">
        <div className='card-body'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 className="card-title fw-semibold pb-1 pt-2">Hotel Categories</h5>
            <button type="button" className="btn btn-dark m-1" onClick={() => {
              navigate(`/hotel-manager/hotel-category/create`)
            }}>
              <FontAwesomeIcon icon="fa-solid fa-plus" />
            </button>
          </div>
          <DataGrid
            rows={HotelCategories || []}
            columns={columns}
            {...dataGridProps}
          />
        </div>
        <AlertDialog ref={confirmDelete} action={deleteHotelCategories}/>
      </div>
    )
  }
  
export default HotelCategories;
