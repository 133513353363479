import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ListingHeader from '../listingPage/ListingHeader';
import Footer from '../landingPage/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'react-datepicker/dist/react-datepicker.module.css';
import './detailedPage.scss';
import { Pagination, Navigation, A11y } from 'swiper/modules';
import 'swiper/css/navigation';
import Cart from './Cart';
import DetailPassRightPanel from './DetailPassRightPanel';
import ReactDatePicker from 'react-datepicker';
import { useGlobalStore } from '../../context/GlobalContext';
import { baseUrl, api, savedImages } from '../../config'
import { getDayRanges } from '../../HotelManager/helper';
import { AmenityIcons } from '../../shared/AmenityIcons';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import Img from '../commons/Img';
function DetailedPage() {
  const { setLoader } = useGlobalStore()
  const { id } = useParams();
  const [hotelDetails, setHotelDetails] = useState(null)
  const [popup, setPopup] = useState(false)
  const { cart, setCart, setAvailablePasses } = useGlobalStore()
  useEffect(() => {
    if (id) {
      setLoader(true)
      axios.get(`${baseUrl}${api}/hotels/details/${id}`)
        .then(response => {
          setHotelDetails({
            ...response.data,
            images: response.data.images.split(','),
            howItWorks: JSON.parse(response.data.howItWorks),
            cancelPolicy: JSON.parse(response.data.cancelPolicy),
            timings: JSON.parse(response.data.timings),
          })
        })
        .catch(error => {
          // Handle login error
        });

      setLoader(false)
    }
  }, [])

  const getAvailablePassesDetail = (date) => {
    setLoader(true)
    axios.get(`${baseUrl}${api}/hotels/available-passes/${id}/${moment(date).format('D-MMM-YYYY')}`)
      .then(response => {
        console.log(hotelDetails, response.data)
        setAvailablePasses(response.data)
      })
      .catch(error => {
        // Handle login error
      });

    setLoader(false)
  }


  const setDate = async (date) => {
    console.log(moment(date).format('DD-MMM-YYYY'))
    await getAvailablePassesDetail(date)
    setCart({
      subTotal: 0,
      items: {},
      date: moment(date).format('DD-MMM-YYYY')
    })
    setPopup(false)
  }
  return (hotelDetails && <>
    <ListingHeader isSearchVisible={false} isHeaderTransparent={false} />
    <>
      {!!hotelDetails.images?.length && <Swiper
        modules={[Navigation, Pagination, A11y]}
        // spaceBetween={0}
        loop={true}
        slidesPerView={hotelDetails.images.length > 3 ? 3 : hotelDetails.images.length}
        // onSlideChange={() => console.log('slide change')}
        slidePrevClass='slick-prev'
        slideNextClass='slick-next'
        className='detail-slider img-slider'
        navigation
        pagination={{ clickable: true }}
      >
        {(hotelDetails?.images || []).map((x, i) => {
          return <SwiperSlide key={i}><Img src={`${baseUrl}${savedImages}/${x}`} style={{ width: "inherit", maxHeight: 423 }} alt="" /></SwiperSlide>
        })}
      </Swiper>}
      <section className="detail-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="dt-information">
                <div className="flex">
                  <div className="title-sec">
                    <div className="title">{hotelDetails?.name}</div>
                    <div className="resort-star">{hotelDetails?.starHotel || 5} star property</div>
                  </div>
                  <div className="review-sec">
                    <div className="rating">8.8</div>
                    <div className="comment">Very Good</div>
                    <div className="reviews">481 reviews</div>
                  </div>
                </div>
                <div className="dt-amni">
                  <div className="flex allamin">
                    {(hotelDetails?.amenities || []).map((x) => {
                      const icon = AmenityIcons.find(y => y.value === x.icon)?.icon
                      return <div className="f1" key={x.name} title={x.name}>
                        {icon}
                        <span>{x.name}</span>
                      </div>
                    })}
                  </div>
                  <div className="btn-save">
                    <button type="button">
                      <Img src="/images/gray-heart-icon.1ca52980.svg" alt="" />
                      Save
                    </button>
                  </div>
                </div>
                <div className="dt-main-desc">
                  <div className="dt-main-desc-content content-info">
                    <p>{hotelDetails?.description}</p>
                  </div>
                  <button className="read-more">Read More</button>
                </div>
                <div className="dt-date-select">
                  <h3 className="heading3">Select a Date</h3>
                  <div className="date-select" onClick={() => setPopup(true)}>
                    <Img src="/images/calendar-header-icon-new.svg" alt="" />
                    <ReactDatePicker minDate={new Date()} id="datepicker" selected={cart.date} onChange={setDate} open={popup} onClickOutside={() => setPopup(false)} />
                  </div>
                </div>
                <div className="dt-select-product-section">
                  <div className="dt-product">
                    {(hotelDetails?.hotelPasses || []).map((x, i) => {
                      return (<div className="items" key={i}>
                        <div className="flex">
                          <div className="dt-pro-left">
                            <div className="title">
                              <Img src="" alt="" />
                              <span>{x.name}</span>
                            </div>
                            <div className="details">
                              <div className="content-info expanded" dangerouslySetInnerHTML={{ __html: x.description }} ></div>
                              <button className="read-more" onClick={(e) => {
                                const isExpanded = e.target.parentElement.firstChild.classList.toggle('expanded')
                                e.target.innerText = isExpanded ? 'Read Less' : 'Read More'
                              }}>Read Less</button>
                            </div>
                          </div>
                          <DetailPassRightPanel key={i}
                            data={{ passes: x.passes, maxPeople: x.maxPeople, hotelPassId: x.id, hotelName: x.name }} />
                        </div>
                      </div>)
                    })}
                  </div>
                </div>
                <div className="dt-timing-section">
                  <h3 className="heading3">Hours</h3>
                  <div className="dt-timing">
                    <div className="flex">
                      <ul>
                        {hotelDetails.timings.map(x => {
                          const daysList = getDayRanges(x.days).split(' and ')
                          return <li key={x.id}>{x.title}
                            <ul>
                              {daysList.map((y, i) => <li key={i}>{y} {x.start} - {x.end}</li>)}
                            </ul>
                          </li>
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="dt-how-it-works">
                  <h3 className="heading3">How it works</h3>
                  <div className="info">
                    {hotelDetails.howItWorks.map((x, i) => {
                      return <div key={i} className="li-items">
                        <div className={'num id-' + x.id}>{x.id}</div>
                        <div className="detail-info">
                          {x.step}
                        </div>
                      </div>
                    })}
                  </div>
                </div>
                <div className="dt-cancellation-section">
                  <h3 className="heading3">Cancellation Policy</h3>
                  <p>Read our full cancellation policy</p>
                  <h3 className="heading3">Cancel Online</h3>
                  <p>You can cancel your booking online for a full refund back to your original payment method
                    or for ResortPass Credit to use another time. Bookings can be cancelled online up until
                    the following times:</p>
                  <ul>
                    {hotelDetails.cancelPolicy.map(x => {
                      return <li key={x.id}><InfoIcon style={{marginRight: '2px'}} />{x.step}</li>
                    })}
                  </ul>
                </div>
                <div className="dt-location-section">
                  <h2>Location</h2>
                  <span>{hotelDetails.location}</span>
                  {/* <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6652.752886211835!2d-112.021363!3d33.517597!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b0d3fd62df91f%3A0xcd2745eb186b8f28!2s2400%20E%20Missouri%20Ave%2C%20Phoenix%2C%20AZ%2085016!5e0!3m2!1sen!2sus!4v1705871066811!5m2!1sen!2sus"
                  width="600" height="450" style={{border: 0}} allowfullscreen="" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <Cart hotelName={hotelDetails.name} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  </>)
}

export default DetailedPage