import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SearchHeader() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState([]);

  // Handler for input change
  const handleInputChange = async (newValue) => {
  setSearchValue(newValue);
  };

  // Handler for form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`/hotel-detail?search=${searchValue}`);
  };

  return (
    <div className="search-header">
      <div className="container">
        <div className="search-form" style={{ backgroundColor: '#efeeee', boxShadow: '10px 10px smokwhite', border: '1px solid transparent', borderRadius: '22px' }}>
          <form onSubmit={handleSubmit}>
            <div className="banner-form">
              <input
                type="text"
                className="form-control"
                placeholder="Search City, State, or Hotel"
                value={searchValue}
                onChange={(e) => handleInputChange(e.target.value)}
              />
              {/* Dropdown for autocomplete suggestions */}
              <ul>
                {(options || []).map((option, index) => (
                  <li key={index} onClick={() => setSearchValue(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            </div>
            <div className="banner-form">
              <input type="submit" value="Search" className="btn btn-primary" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SearchHeader;
