import React from 'react'

function Pass({pass}) {
  const price = !!Number(pass.price) ? `$${pass.price}`: 'Free'  
  return (
    <div className="e1">
      <h6 className='capitalize'>{pass.passType}</h6>
      <span>starting at</span>
      <div><strong>{price}</strong> {price !== 'Free' && 'USD'}</div>
    </div>
  )
}

export default Pass