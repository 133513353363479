/* eslint-disable jsx-a11y/anchor-is-valid */
// Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useGlobalStore } from '../../context/GlobalContext';
import { baseUrl, api } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: 'normal_user@example.com',
    password: 'your_user_password'
  });
  const [error, setError] = useState(null); // State for storing error message
  const { setIsUserLoggedIn } = useGlobalStore(); // State for storing error message

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${baseUrl}${api}/login`, formData)
      .then(response => {
        localStorage.setItem('userToken', response.data.token);
        setIsUserLoggedIn(true)
      })
      .catch(error => {
        // Handle login error
        setError('Invalid email or password. Please try again.'); // Set error message
      });
  };
  return (
    <div className="login-back">
      <div className="flex h-100 align-items-center justify-content-center">
        <div className="login-box">
          <h4>Log in to <a >ResortRange</a> </h4>
          {error && <div className="alert alert-danger">{error}</div>}
          <p>Don't have an account yet? <a href="/register">Sign Up</a></p>
          <form onSubmit={handleSubmit}>
          <div>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleInputChange} placeholder="Email Address" />
            </div>
            <div>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange} placeholder="Password"
              />
            </div>
            <div>
              <input type="submit" value="submit" />
            </div>
          </form>
          <a className="forgot">Forgot Password?</a>
          <div className="other-login">
            Are you a hotel manager? <a onClick={() => navigate(`/hotel-manager/login`)}>Log in here</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
