import { autoSizeOptions } from "./autoSizeOptions";

export const dataGridProps = {
  density:"compact",
  autoHeight: true,
  getRowId:e => e.id ?? e,
  showColumnVerticalBorder: true,
  autosizeOptions: autoSizeOptions
}


// apiRef={apiRef}
// sortModel={sortModel}
// initialState={{
//   pagination: {
//     paginationModel: {
//       page: 0,
//       pageSize: 20,
//     },
//   },
// }}