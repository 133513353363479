import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../commons/AdminDashboard.scss';
import axios from 'axios';
import moment from 'moment';
import { baseUrl, api } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalStore } from '../../context/GlobalContext';
import { toast } from 'react-toastify';
import AlertDialog from '../../shared/AlertDialog';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { dataGridProps } from '../../constants/dataGridProps';
import { blue, green, red } from '@mui/material/colors';
import { authConstants } from '../../constants/authConstant';

function Bookings() {

  // const [expandedRowIds, setExpandedRowIds] = useState([]);

  // const toggleExpand = (id) => {
  //   setExpandedRowIds((prev) =>
  //     prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
  //   );
  // };
  // const getRowHeight = (params) => {
  //   return expandedRowIds.includes(params.id) ? 100 : 50; // Adjust height as needed
  // };

  // const renderCellExpand = (params) => {
  //   const isExpanded = expandedRowIds.includes(params.id);
  //   return (
  //     <div style={{ display: 'flex', flexDirection: 'column' }}>
  //       <div>{params.value}</div>
  //       {isExpanded && <div style={{ marginTop: 10 }}>Expandable content for {params.id}</div>}
  //     </div>
  //   );
  // };

  const confirmDelete = useRef()
  const { setLoader, getAuthConfig } = useGlobalStore()

  const { id } = useParams()
  const navigate = useNavigate();
  const [bookingList, setBookingList] = useState([]);

  const getBookingList = async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${baseUrl}${api}/admin/hotels/bookings/${id}`, getAuthConfig(authConstants.adminToken))
      
      setBookingList(res?.data || [])
    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }

  useEffect(() => {
    getBookingList()
  }, [])

  const deleteBooking = async (id) => {
    try {
      setLoader(true)
      const res = await axios.delete(`${baseUrl}${api}/admin/bookings/${id}`)
      if (res.status === 200) {
        setBookingList(bookingList.filter(x => x.id !== id))
        toast.success("Booking deleted successfully!")
      
      }
    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }

  const columns = [
    // {
    //   field: '',
    //   headerName: 'Username',
    //   valueGetter: (value, row) => `${row.User?.username}`,
    //   flex: 1
    // },
    {
      field: 'bookingDate',
      headerName: 'Booking Date',
      flex: 1,
      valueGetter: (value, row) => moment(row.bookingDate).format('D/MMM/YYYY'),
      sortComparator: (v1, v2, param1, param2) => {
        if (moment(param1.value).isBefore(param2.value)) {
          return 1
        }
        return -1
      },
    },
    {
      field: 'totalPrice',
      headerName: 'Total Price',
      flex: 1
    },
    {
      field: '',
      headerName: 'Status',
      valueGetter: (value, row) => 'Booked',
      flex: 1
    },
    {
      field: 'BookingDetails',
      headerName: 'Details',
      valueGetter: (value, row) => {
        return (value || []).map(x=>{
          return `${x?.Pass?.Hotel_Pass?.name}(${x?.Pass?.passType}) x ${x.quantity} @ ${x.price}`
        })
      },
      flex: 1
    },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   type: 'actions',
    //   width: 100,
    //   flex: 1,
    //   getActions: ({row}) => {
    //     return [
    //       <GridActionsCellItem
    //         icon={<FontAwesomeIcon icon="fa-regular fa-rectangle-list" color={green[700]}/>}
    //         label="View Booking"
    //         onClick={() => navigate(`/admin/hotel-bookings/${row.id}`)}
    //       />,
    //       <GridActionsCellItem
    //         icon={<FontAwesomeIcon icon="fa-solid fa-pen-to-square" color={blue[700]}/>}
    //         label="Edit"
    //         onClick={() => navigate(`/admin/users/edit/${row.id}`)}
    //       />,
    //       <GridActionsCellItem
    //         icon={<FontAwesomeIcon icon="fa-solid fa-trash" color={red[700]} />}
    //         label="Delete"
    //         onClick={() => confirmDelete.current.handleClickOpen(row)}
    //       />,
    //     ]
    //   }
    // },
  ];
  return (
    <div className="card w-100 m-0">
      <div className="card-body">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="card-title fw-semibold pb-1 pt-2">Bookings</h5>
          {/* <button type="button" className="btn btn-dark m-1" onClick={() => {
              navigate('/admin/bookings/create')
            }}>
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </button> */}
        </div>
        <DataGrid
          rows={bookingList}
          columns={columns}
          // columns={columns.map((col) => ({
          //   ...col,
          //   renderCell: renderCellExpand,
          // }))}
          // {...dataGridProps}
          // getRowHeight={getRowHeight}
          // onRowClick={(params) => toggleExpand(params.id)}
        />
      </div>
      <AlertDialog ref={confirmDelete} action={deleteBooking}/>
    </div>
  );
}

export default Bookings