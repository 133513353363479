import React from 'react';
import AdminPrivateRoutes from './AdminPrivateRoutes';
import AdminPublicRoutes from './AdminPublicRoutes';
import { useGlobalStore } from '../context/GlobalContext';
import AdminLayout from './commons/AdminLayout';

const AdminModule = () => {
  const { isAdminLoggedIn } = useGlobalStore();

  return isAdminLoggedIn ? <AdminLayout><AdminPrivateRoutes /></AdminLayout> : <AdminPublicRoutes />;
};

export default AdminModule;
