// LandingPage.js
import React from 'react';
import Header from '../commons/Header';
import Banner from './Banner';
import IconDescription from './IconDescription';
import ResortList from './ResortList';
import Explore from './Explore';
import Asking from './Asking';
import Footer from './Footer';

function LandingPage() {
  return (
    <div className="LandingPage">
      <Header />
      <Banner />
      <IconDescription />
      <ResortList />
      <Explore />
      <Asking />
      <Footer />
    </div>
  );
}

export default LandingPage;
