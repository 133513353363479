// HotelHotelManagerLogin.js
import React, { useState } from 'react';
import axios from 'axios';
import { useGlobalStore } from '../../context/GlobalContext';
import { baseUrl, api } from '../../config';
import useLocalStorage from '../../Hooks/useLocalStorage';

function AdminLogin() {
  const [formData, setFormData] = useState({
    email: 'Admin@example.com',
    password: 'your_admin_password'
  });
  const [error, setError] = useState(null); // State for storing error message
  const { setIsAdminLoggedIn } = useGlobalStore(); // State for storing error message

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${baseUrl}${api}/admin-login`, formData)
      .then(response => {
        localStorage.setItem('adminToken', response.data.token);
        setIsAdminLoggedIn(true)
      })
      .catch(error => {
        // Handle login error
        setError('Invalid email or password. Please try again.'); // Set error message
      });
  };

  return (
    <div className="login-back">
      <div className="flex h-100 align-items-center justify-content-center">
        <div className="login-box">
          <h4>Log in to <a >Admin Panel</a> </h4>
          {error && <div className="alert alert-danger">{error}</div>} {/* Display error message if error exists */}
          <form onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleInputChange} placeholder="Email Address" />
            </div>
            <div>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange} placeholder="Password"
              />
            </div>
            <div>
              <input type="submit" value="submit" />
            </div>
          </form>
          <a className="forgot">Forgot Password?</a>
        </div>
      </div>
    </div>
  )
}

export default AdminLogin