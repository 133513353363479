import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import '../HotelManagerDashboard/HotelManagerDashboard.scss';
import { baseUrl, api } from '../../config'
import { useGlobalStore } from '../../context/GlobalContext';
import { DataGrid } from '@mui/x-data-grid';
import { dataGridProps } from '../../constants/dataGridProps';

function HotelBookings({hotelId}) {
  const confirmDelete = useRef()
  const { setLoader } = useGlobalStore()
  const navigate = useNavigate();
  const [bookingList, setBookingList] = useState([]);

  const getHotelBookingsList = async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${baseUrl}${api}/bookings/${hotelId}`)
      setBookingList((res?.data || []))
    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }

  useEffect(() => {
    if(hotelId) {
      getHotelBookingsList()
    }
  }, [hotelId])

  const columns = [
    {
      field: 'bookingDate',
      headerName: 'Booking Date',
      flex: 1,
      valueGetter: (value, row) => moment(row.bookingDate).format('D/MMM/YYYY'),
      // sortComparator: (v1, v2, param1, param2) => {
      //   if (moment(param1.value).isBefore(param2.value)) {
      //     return 1
      //   }
      //   return -1
      // },
    },
    {
      field: 'totalPrice',
      headerName: 'Total Price',
      flex: 1
    },
    {
      field: '',
      headerName: 'Status',
      valueGetter: (value, row) => 'Booked',
      flex: 1
    },
    {
      field: 'BookingDetails',
      headerName: 'Details',
      valueGetter: (value, row) => {
        return (value || []).map(x=>{
          return `${x?.Pass?.Hotel_Pass?.name}(${x?.Pass?.passType}) x ${x.quantity} @ ${x.price}`
        })
      },
      flex: 1
    },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   type: 'actions',
    //   width: 100,
    //   flex: 1,
    //   getActions: ({row}) => {
    //     return [
    //       <GridActionsCellItem
    //         icon={<FontAwesomeIcon icon="fa-regular fa-rectangle-list" color={green[700]}/>}
    //         label="View Booking"
    //         onClick={() => navigate(`/admin/hotel-bookings/${row.id}`)}
    //       />,
    //       <GridActionsCellItem
    //         icon={<FontAwesomeIcon icon="fa-solid fa-pen-to-square" color={blue[700]}/>}
    //         label="Edit"
    //         onClick={() => navigate(`/admin/users/edit/${row.id}`)}
    //       />,
    //       <GridActionsCellItem
    //         icon={<FontAwesomeIcon icon="fa-solid fa-trash" color={red[700]} />}
    //         label="Delete"
    //         onClick={() => confirmDelete.current.handleClickOpen(row)}
    //       />,
    //     ]
    //   }
    // },
  ];
  return (
    <div className="card w-100 m-0">
      <div className="card-body">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="card-title fw-semibold pb-1 pt-2">Bookings</h5>
        </div>
        <DataGrid
          rows={bookingList || []}
          columns={columns}
          {...dataGridProps}
        />
      </div>
      {/* <AlertDialog ref={confirmDelete} action={deleteBooking}/> */}
    </div>
  );

}

export default HotelBookings

