
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DryCleaningIcon from '@mui/icons-material/DryCleaning'; //towel
import SpaIcon from '@mui/icons-material/Spa'; //spa
import PoolIcon from '@mui/icons-material/Pool'; //pool
import HotTubIcon from '@mui/icons-material/HotTub'; //hottub
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'; //gym
import LocalDrinkIcon from '@mui/icons-material/LocalDrink'; //drink
import FastfoodIcon from '@mui/icons-material/Fastfood'; //fastfood
import NightlifeIcon from '@mui/icons-material/Nightlife'; //night life
import LiquorIcon from '@mui/icons-material/Liquor'; //liqour
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast'; //breakfast
import WineBarIcon from '@mui/icons-material/WineBar'; //bar
import BrunchDiningIcon from '@mui/icons-material/BrunchDining'; //brunch
import SingleBedIcon from '@mui/icons-material/SingleBed'; //single bed
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatFlatAngledIcon from '@mui/icons-material/AirlineSeatFlatAngled';
import HotelIcon from '@mui/icons-material/Hotel';
import DeckIcon from '@mui/icons-material/Deck';
import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import FenceIcon from '@mui/icons-material/Fence';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import KitesurfingIcon from '@mui/icons-material/Kitesurfing';
import HouseboatIcon from '@mui/icons-material/Houseboat';
import SurfingIcon from '@mui/icons-material/Surfing';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import DiningIcon from '@mui/icons-material/Dining';
import ShowerIcon from '@mui/icons-material/Shower';
import VapingRoomsIcon from '@mui/icons-material/VapingRooms';
import BathtubIcon from '@mui/icons-material/Bathtub';
import DryIcon from '@mui/icons-material/Dry';


export const AmenityIcons = [
  { value: 'AcUnitIcon', icon: <AcUnitIcon /> },
  { value: 'DryCleaningIcon', icon: <DryCleaningIcon /> },
  { value: 'SpaIcon', icon: <SpaIcon /> },
  { value: 'PoolIcon', icon: <PoolIcon /> },
  { value: 'HotTubIcon', icon: <HotTubIcon /> },
  { value: 'FitnessCenterIcon', icon: <FitnessCenterIcon /> },
  { value: 'LocalDrinkIcon', icon: <LocalDrinkIcon /> },
  { value: 'FastfoodIcon', icon: <FastfoodIcon /> },
  { value: 'NightlifeIcon', icon: <NightlifeIcon /> },
  { value: 'LiquorIcon', icon: <LiquorIcon /> },
  { value: 'FreeBreakfastIcon', icon: <FreeBreakfastIcon /> },
  { value: 'WineBarIcon', icon: <WineBarIcon /> },
  { value: 'BrunchDiningIcon', icon: <BrunchDiningIcon /> },
  { value: 'SingleBedIcon', icon: <SingleBedIcon /> },
  { value: 'AirlineSeatFlatIcon', icon: <AirlineSeatFlatIcon /> },
  { value: 'AirlineSeatFlatAngledIcon', icon: <AirlineSeatFlatAngledIcon /> },
  { value: 'HotelIcon', icon: <HotelIcon /> },
  { value: 'DeckIcon', icon: <DeckIcon /> },
  { value: 'CameraOutdoorIcon', icon: <CameraOutdoorIcon /> },
  { value: 'OutdoorGrillIcon', icon: <OutdoorGrillIcon /> },
  { value: 'FenceIcon', icon: <FenceIcon /> },
  { value: 'BeachAccessIcon', icon: <BeachAccessIcon /> },
  { value: 'KitesurfingIcon', icon: <KitesurfingIcon /> },
  { value: 'HouseboatIcon', icon: <HouseboatIcon /> },
  { value: 'SurfingIcon', icon: <SurfingIcon /> },
  { value: 'FireplaceIcon', icon: <FireplaceIcon /> },
  { value: 'DiningIcon', icon: <DiningIcon /> },
  { value: 'ShowerIcon', icon: <ShowerIcon /> },
  { value: 'VapingRoomsIcon', icon: <VapingRoomsIcon /> },
  { value: 'BathtubIcon', icon: <BathtubIcon /> },
  { value: 'DryIcon', icon: <DryIcon /> },
]
