/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Asking = () => {
  return (
    <section className="asking">
      <div className="container">
        <div className="query">Are you a hotel manager interested in managing your hotel with us?</div>
        <a className="btn-outline-primary">Become a Hotel Manager</a>
      </div>
    </section>
  );
}

export default Asking;
