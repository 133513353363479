import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './landingPage/LandingPage';
import Login from './loginPage/Login';
import Register from './registerPage/Register';
import ListingPage from './listingPage/ListingPage';
import DetailedPage from './detailedPage/DetailedPage';
import MyBookingPage from './myBookingPage/MyBookingPage';
import { useGlobalStore } from '../context/GlobalContext';

const UserModule = () => {
  const { isUserLoggedIn } = useGlobalStore();

  return <Routes>
    <Route index element={<LandingPage />} />
    <Route path='login' element={!isUserLoggedIn ? <Login /> : <Navigate to="/" />} />
    <Route path='register' element={!isUserLoggedIn ? <Register /> : <Navigate to="/" />} />
    <Route path='listing' element={<ListingPage />} />
    <Route path='details/:id' element={<DetailedPage />} />
    <Route path='my-bookings' element={!!isUserLoggedIn ? <MyBookingPage /> : <Navigate to="/" />} />
  </Routes>
};

export default UserModule;