import { Navigate, Route, Routes } from 'react-router-dom';
import Hotels from './hotels/Hotels';
import HotelForm from './HotelForm/HotelForm';
import Users from './users/Users';
import UserForm from './userForm/UserForm';
import HotelAccess from './HotelAccess/HotelAccess';
import Bookings from './bookings/Bookings';
import HotelManagers from './HotelManagers/HotelManagers';
import HotelManagerForm from './HotelManagerForm/HotelManagerForm';
import AdminDashboard from './adminDashboard/AdminDashboard';
import Amenities from './amenities/Amenities';
import { useGlobalStore } from '../context/GlobalContext';
import AmenitiesForm from './AmenitiesForm/AmenitiesForm';

const AdminPrivateRoutes = () => {

  return <Routes>
    <Route path='' element={<AdminDashboard />} />
    <Route path='/hotel' element={<Hotels />} />
    <Route path='/hotel/create' element={<HotelForm />} />
    <Route path='/hotel/edit/:id' element={<HotelForm />} />
    <Route path='/hotel/access/:id' element={<HotelAccess />} />
    <Route path='/users' element={<Users />} />
    <Route path='/users/create' element={<UserForm />} />
    <Route path='/users/edit/:id' element={<UserForm />} />
    <Route path='/hotel-managers' element={<HotelManagers />} />
    <Route path='/hotel-managers/create' element={<HotelManagerForm />} />
    <Route path='/hotel-managers/edit/:id' element={<HotelManagerForm />} />
    <Route path='/hotel-bookings/:id' element={<Bookings />} />
    <Route path='/amenities' element={<Amenities />} />
    <Route path='/amenities/create' element={<AmenitiesForm />} />
    <Route path='/amenities/edit/:id' element={<AmenitiesForm />} />
    <Route path='*' element={<Navigate to="/admin"/>} />
  </Routes>
};

export default AdminPrivateRoutes;