import React, { useEffect, useState } from 'react';
import '../commons/AdminDashboard.scss';
import axios from 'axios';
import { baseUrl, api } from '../../config'
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalStore } from '../../context/GlobalContext';
import { toast } from 'react-toastify';
import { authConstants } from '../../constants/authConstant';

function HotelAccess() {

  const { setLoader, getAuthConfig } = useGlobalStore()
  const navigate = useNavigate();
  const [hotelAccess, setHotelAccess] = useState(null);
  const [userList, setUserList] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams();

  const getHotelAccess = async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${baseUrl}${api}/admin/hotels/access/${id}`, getAuthConfig(authConstants.adminToken))
      setHotelAccess(res?.data?.adminUserId)
    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }

  const getUserList = async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${baseUrl}${api}/admin/hotel-managers`, getAuthConfig(authConstants.adminToken))
      setUserList(res?.data || [])
    } catch (e) {
      console.error(e)
    }
    setLoader(false)
  }

  useEffect(() => {
    getUserList()
  }, [])
  
  useEffect(() => {
    if (userList) {
      getHotelAccess()
    }
  }, [userList])

  const handleSubmit = async () => {
    const res = await axios.patch(`${baseUrl}${api}/admin/hotels/${id}`, { adminUserId: hotelAccess }, getAuthConfig(authConstants.adminToken))
    toast.success("Hotel Manager assigned successfully!")
  }

  return (
    <div className="card m-0">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Assign a Hotel Manager</h5>
        {error && <div className="alert alert-danger">{error}</div>} {/* Display error message if error exists */}
        <form>
          <div className="mb-3">
            <label className="form-label">User</label>
            <select className="form-control" onChange={(e) => {
              setHotelAccess(e.target.value)
            }}>
              {
                (userList || []).map((x, i) => {
                  return <option key={i} selected={x.id === hotelAccess} label={x.email} value={x.id} />
                })
              }
            </select>
          </div>
          <button type="button" className="btn btn-primary" onClick={handleSubmit}>Assign</button>
        </form>
      </div>
    </div>
  )
}

export default HotelAccess
