import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import React, { useEffect, useRef, useState } from 'react'
import AlertDialog from '../../shared/AlertDialog'
import { useGlobalStore } from '../../context/GlobalContext'
import { red } from '@mui/material/colors'
import { dataGridProps } from '../../constants/dataGridProps'
import axios from 'axios'
import { api, baseUrl } from '../../config'
import { toast } from 'react-toastify';

function HotelHowItWorks({ hotelId }) {
  const confirmDelete = useRef()
  const [error, setError] = useState(null)
  const { setLoader } = useGlobalStore()
  const [howItWorks, setHowItWorks] = useState([]);
  const [newHowItWorks, setNewHowItWorks] = useState([]);

  const columns = [
    {
      field: 'id',
      headerName: 'Step No.',
      flex: 1
    },
    {
      field: 'step',
      headerName: 'Step',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 100,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-trash" color={red[700]} />}
            label="Delete"
          onClick={(e) => {
            confirmDelete.current.handleClickOpen(row)
          }}
          />,
        ]
      }
    },
  ];

  const fetchHowItWorks = async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${baseUrl}${api}/hotels/how-it-works/${hotelId}`)
      setHowItWorks(res.data || [])
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    if(hotelId) {
      fetchHowItWorks()
    }
  }, [hotelId])

  const handleSubmit = async () => {
    if(!newHowItWorks) {
      setError('Please enter a step')
      return
    }
    try {
      setLoader(true)
      const policies = [...howItWorks, { id: howItWorks.length + 1, step: newHowItWorks }]
      await axios.post(`${baseUrl}${api}/hotels/how-it-works/${hotelId}`, policies)
      setHowItWorks(policies)
      setNewHowItWorks('')
      toast.success('New step added successfully!')
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message)
    } finally {
      setLoader(false)
    }
  }

  const deletePolicy = async (id) => {
    try {
      setLoader(true)
      const policies = howItWorks.filter(x => x.id !== id).map((x, i) => { return {...x, id: i+1}})
      const res = await axios.post(`${baseUrl}${api}/hotels/how-it-works/${hotelId}`, policies)
      if (res.status === 200) {
        setHowItWorks(policies)
        toast.success('Step removed successfully!')
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message)
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="card w-100 m-0">
      <div className='card-body'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="card-title fw-semibold pb-1 pt-2">How It Works</h5>
          {error && <div className="alert alert-danger">{error}</div>} {/* Display error message if error exists */}
        </div>
        <form className='mb-3'>
          <div className="mb-3">
            <label className="form-label">Add new step</label>
            <input type="text" className="form-control" value={newHowItWorks} onChange={(e) => {
              setNewHowItWorks(e.target.value)
            }} />
          </div>
          <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add</button>
        </form>
        <DataGrid
          rows={howItWorks || []}
          columns={columns}
          {...dataGridProps}
        />
      </div>
      <AlertDialog ref={confirmDelete} action={deletePolicy} />
    </div>
  )
}

export default HotelHowItWorks