// Register.jsx

import React from 'react';

const Register = () => {
  return (
    <div className="login-back">
      <div className="flex h-100 align-items-center justify-content-center">
        <div className="login-box">
          <h4>Create <a >ResortRange</a> Account</h4>
          <p>Already have an account? <a href="/login">Log in</a></p>
          <form action="">
            <div>
              <input type="text" name="" id="" placeholder="Email Address" />
            </div>
            <div>
              <input type="password" name="" id="" placeholder="Password" />
            </div>
            <div>
              <input type="password" name="" id="" placeholder="Confirm Password" />
            </div>
            <div>
              <input type="submit" name="" id="" value="Submit" />
            </div>
          </form>

          {/* <div className="or">
            <span>or</span>
          </div>

          <a  className="signwith">
            <img src="/images/google-icon.svg" alt="" /> Log in with Google
          </a>
          <a  className="signwith">
            <img src="/images/facebook-icon.svg" alt="" /> Log in with Facebook
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Register;
