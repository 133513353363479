import React, { useEffect, useState } from 'react'
import { Country, State, City } from 'country-state-city';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { baseUrl, api } from '../../config'
import { useGlobalStore } from '../../context/GlobalContext';
import { toast } from 'react-toastify';
import { authConstants } from '../../constants/authConstant';

const countries = Country.getAllCountries();
function HotelForm() {
  const { setLoader, getAuthConfig } = useGlobalStore()
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [name, setName] = useState('')
  const [error, setError] = useState(null)
  const navigate = useNavigate(null)
  const { id } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      id: id ?? undefined,
      name: name,
      country: selectedCountry,
      state: selectedState?.isoCode ?? selectedState,
      city: selectedCity,
    }
    try {
      setLoader(true)
      if (id) {
        await axios.put(`${baseUrl}${api}/admin/hotels/${id}`, formData, getAuthConfig(authConstants.adminToken))
      } else {
        await axios.post(`${baseUrl}${api}/admin/hotels`, formData, getAuthConfig(authConstants.adminToken))
      }
      setLoader(false)
      
      toast.success("Hotel saved successfully!")
      navigate('/admin/hotel')
    } catch (e) {
      setError('Invalid form values. Please try again.'); // Set error message
    }
    setLoader(false)
  };

  const getData = async () => {
    try {
      if (id) {
        setLoader(true)
        const response = await axios.get(`${baseUrl}${api}/admin/hotels/${id}`, getAuthConfig(authConstants.adminToken))
        setName(response.data.name)
        setSelectedCountry(response.data.country)
        setSelectedState(response.data.state)
      }
    } catch (e) {
      setError('Invalid form values. Please try again.'); // Set error message
    }
    setLoader(false)
  }
  console.log('aaaaaaaaaaaaaaaaaa', selectedCountry)
  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (selectedCountry?.isoCode) {
      setStates(State.getStatesOfCountry(selectedCountry.isoCode))
    } else if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry))
    }
  }, [selectedCountry])

  useEffect(() => {
    if (selectedState?.countryCode) {
      setCities(City.getCitiesOfState(selectedState.countryCode, selectedState.isoCode))
    } else if (selectedState) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState))
    }
  }, [selectedState])

  return (
    <div className="card m-0">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">{id ? 'Edit Hotel' : 'Add a Hotel'}</h5>
        {error && <div className="alert alert-danger">{error}</div>} {/* Display error message if error exists */}
        <form>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <input type="text" className="form-control" defaultValue={name} onChange={(e) => {
              setName(e.target.value)
            }} />
          </div>
          <div className="mb-3">
            <label className="form-label">Country</label>
            <select className="form-control" defaultValue={selectedCountry} onChange={(e) => {
              setSelectedCountry(e.target.value)
            }}>
              {
                (countries || []).map((x, i) => {
                  return <option key={i} label={x.name} value={x.isoCode} selected={selectedCountry===x.isoCode}  />
                })
              }
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">State</label>
            <select className="form-control" defaultValue={selectedState} onChange={(e) => {
              setSelectedState(State.getStateByCode(e.target.value))
            }}>
              {
                (states || []).map((x, i) => {
                  return <option key={i} label={x.name} value={x.isoCode} />
                })
              }
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">City</label>
            <select className="form-control" defaultValue={selectedCity} onChange={(e) => {
              setSelectedCity(e.target.value)
            }}>
              {
                (cities || []).map((x, i) => {
                  return <option key={i} label={x.name} value={x.name} />
                })
              }
            </select>
          </div>
          <button type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default HotelForm