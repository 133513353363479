// Navigation.js
import React from 'react';
import { useNavigate } from 'react-router';

function Navigation({ type }) {

  const navigation = useNavigate()
  return (
    <nav className={type === "side" ? "dropdown-cs" : "dropdown-cs-menu"}>
      <div className="flex">
        <div className="about">
          <p>Unforgettable resorts and memories await</p>
          <a href="/about-us">About ResortPass</a>
        </div>
        <div className="menu">
          <nav>
            <h3>Guests</h3>
            <ul>
              <li><a onClick={()=>navigation('/my-bookings')}>Help</a></li>
            </ul>
          </nav>
          <nav>
            <h3>Hotels</h3>
            <ul>
              <li><a onClick={()=>navigation('/my-bookings')}>List My Hotel</a></li>
              <li><a onClick={()=>navigation('')}>Marketplace</a></li>
              <li><a onClick={()=>navigation('')}>Press</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
