/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="container-fluid">
          <div className="main-footer">
            <div className="flex">
              <div className="first">
                <div className="logo">
                  <a >RESORTROVER</a>
                </div>
                <div className="social">
                  <a ><img src="/images/instagram-icon.0bc55009.svg" alt="" /></a>
                  <a ><img src="/images/twitter-icon.2b4e791b.svg" alt="" /></a>
                  <a ><img src="/images/facebook-icon-black.f4ee1771.svg" alt="" /></a>
                  <a ><img src="/images/pinterest-icon.ba2c7802.svg" alt="" /></a>
                </div>
              </div>
              <div className="second">
                <nav>
                  <h3>Explore</h3>
                  <ul>
                    <li><a >Help & FAQ</a></li>
                    <li><a >Reviews</a></li>
                    <li><a >Media</a></li>
                  </ul>
                </nav>
                <nav>
                  <h3>About</h3>
                  <ul>
                    <li><a >About Us</a></li>
                    <li><a >Contact Us</a></li>
                    <li><a >Careers</a></li>
                    <li><a >Become a Hotel Manager</a></li>
                    <li><a >Terms and Conditions</a></li>
                    <li><a >Privacy Policy</a></li>
                  </ul>
                </nav>
              </div>
              <div className="third">
                <div className="flex">
                  <div className="descr">
                    <span>
                      Download the RESORTROVER App
                    </span>
                    <div className="rating">
                      4.9
                    </div>
                    <div className="rating-icons">
                      <img src="/images/star-solid-new.c0617310.svg" alt="" />
                      <img src="/images/star-solid-new.c0617310.svg" alt="" />
                      <img src="/images/star-solid-new.c0617310.svg" alt="" />
                      <img src="/images/star-solid-new.c0617310.svg" alt="" />
                      <img src="/images/star-solid-new.c0617310.svg" alt="" />
                    </div>
                    <div className="rating-info">
                      1.1K Ratings
                    </div>
                    <div className="app">
                      <a href="javascript"><img src="/images/apple-store-logo.5b9df203.svg" alt="" /></a>
                      <a href="javascript"><img src="/images/google-store-logo.07cf0db4.svg" alt="" /></a>
                    </div>
                  </div>
                  <div className="map">
                    <img src="/images/ios_app_image@2x.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy">
        © 2024 RESORTROVER, Inc.
      </div>
    </footer>
  );
};

export default Footer;
