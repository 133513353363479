import React, { useEffect, useState } from 'react'
import { Country, State, City } from 'country-state-city';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseUrl, api, savedImages } from '../../config'
import { useGlobalStore } from '../../context/GlobalContext';
import { toast } from 'react-toastify';

const stars = [
  { value: 5, label: '5 Stars' },
  { value: 4, label: '4 Stars' },
  { value: 3, label: '3 Stars' },
  { value: 2, label: '2 Stars' },
  { value: 1, label: '1 Star' },
]

function HotelForm({id}) {
  const { setLoader } = useGlobalStore()
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStar, setSelectedStar] = useState(5);
  const [files, setFiles] = useState([])
  const [oldFiles, setOldFiles] = useState([])
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [location, setLocation] = useState('')
  const [error, setError] = useState(null)
  const navigate = useNavigate(null)
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categories, setCategories] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const base64Array = await fileListToBase64Array(files)

    const formData = {
      id: id ?? undefined,
      name: name,
      description: description,
      location: location,
      country: selectedCountry?.isoCode ?? selectedCountry,
      state: selectedState?.isoCode ?? selectedState,
      city: selectedCity,
      images: base64Array,
      oldImages: id ? oldFiles : undefined,
      starHotel: selectedStar,
    }
    try {
      setLoader(true)
      // if (id) {
      const res = await axios.put(`${baseUrl}${api}/hotels/${id}`, formData)
      // } else {
      //   await axios.post(`${baseUrl}${api}/hotels`, formData)
      // }

      toast.success("Hotel saved successfully!")
      setLoader(false)

      navigate('/hotel-manager')
    } catch (e) {
      setLoader(false)
      setError('Invalid form values. Please try again.'); // Set error message
    }
  };

  const getData = async () => {
    if (id) {
      setLoader(true)
      await axios.get(`${baseUrl}${api}/hotels/${id}`)
        .then(response => {
          setName(response.data.name)
          setLocation(response.data.location || '')
          setDescription(response.data.description)
          setSelectedCountry(response.data.country)
          setSelectedState(response.data.state)
          setSelectedStar(response.data.starHotel)
          setSelectedCity(response.data.city)
          setOldFiles((response.data.images || '').split(',').filter(img => img))
          setSelectedCategory(response.data.hotelCategoriesId ? response.data.hotelCategoriesId : null)
        })
        .catch(error => {
          // Handle login error
          setError('Invalid form values. Please try again.'); // Set error message
        });
    }
    setCountries(Country.getAllCountries())
    await axios.get(`${baseUrl}${api}/hotelCategories`)
      .then(response => {
        // Set categories Option
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    setLoader(false)
  }

  useEffect(() => {
    if(id) {
      getData()
    }
  }, [id])

  useEffect(() => {
    if (selectedCountry?.isoCode) {
      setStates(State.getStatesOfCountry(selectedCountry.isoCode))
    } else if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry))
    }
  }, [selectedCountry])

  useEffect(() => {
    if (selectedState?.countryCode) {
      setCities(City.getCitiesOfState(selectedState.countryCode, selectedState.isoCode))
    } else if (selectedState) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState))
    }
  }, [selectedState])

  const fileListToBase64Array = (fileList) => {
    const promises = [];

    for (let i = 0; i < fileList?.length || 0; i++) {
      const file = fileList[i];
      promises.push(getBase64(file));
    }

    return Promise.all(promises);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  return (
    <div className="card m-0">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Hotel Profile</h5>
        {error && <div className="alert alert-danger">{error}</div>} {/* Display error message if error exists */}
        <form>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <input type="text" className="form-control" defaultValue={name} onChange={(e) => {
              setName(e.target.value)
            }} />
          </div>
          <div className="mb-3">
            <label className="form-label">Country</label>
            <select className="form-control" onChange={(e) => {
              setSelectedCountry(Country.getCountryByCode(e.target.value))
            }}>
              {
                (countries || []).map((x, i) => {
                  return <option key={i} selected={x.isoCode === selectedCountry} label={x.name} value={x.isoCode} />
                })
              }
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">State</label>
            <select className="form-control" onChange={(e) => {
              setSelectedState(State.getStateByCode(e.target.value))
            }}>
              {
                (states || []).map((x, i) => {
                  return <option selected={x.isoCode === selectedState} key={i} label={x.name} value={x.isoCode} />
                })
              }
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">City</label>
            <select className="form-control" onChange={(e) => {
              setSelectedCity(e.target.value)
            }}>
              {
                (cities || []).map((x, i) => {
                  return <option key={i} selected={x.name === selectedCity} label={x.name} value={x.name} />
                })
              }
            </select>
          </div>
          {/* <div className="mb-3">
            <label className="form-label">Category</label>
            <select className="form-control" onChange={(e) => {
              setSelectedCategory(e.target.value)
            }}>
              {
                (categories || []).map((x, i) => {
                  return <option key={i} selected={x.name === selectedCategory} label={x.name} value={x.name} />
                })
              }
            </select>
          </div> */}
          <div className="mb-3">
            <label className="form-label">Star</label>
            <select className="form-control" onChange={(e) => {
              setSelectedStar(e.target.value)
            }}>
              {
                (stars || []).map((x, i) => {
                  return <option key={i} selected={x.value === selectedStar} label={x.label} value={x.value} />
                })
              }
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Address</label>
            <textarea className="form-control" defaultValue={location} onChange={(e) => {
              setLocation(e.target.value)
            }} />
          </div>
          <div className="mb-3">
            <label className="form-label">Description</label>
            <textarea className="form-control" defaultValue={description} onChange={(e) => {
              setDescription(e.target.value)
            }} />
          </div>
          <div className="mb-3">
            <label className="form-label">Images</label>
            {
              !!oldFiles?.length && <div className='d-flex'>
                {
                  (oldFiles || []).map((x, i) => {
                    return <div className="card" key={i}>
                      <div>
                        <span className='btn btn-danger' style={{ position: 'absolute' }} onClick={(e) => {
                          setOldFiles(oldFiles.filter(y => {
                            return y != x
                          }))
                        }}><i className='ti ti-trash-x'></i></span>
                        <img src={`${baseUrl}${savedImages}/${x}`} className="card-img-top" style={{ maxHeight: '250px' }} alt="..." />
                      </div>
                    </div>
                  })
                }
              </div>
            }
            <input type='file' accept='image/*' multiple onChange={(e) => {
              setFiles(e.target.files)
            }} className="form-control" />
          </div>
          <button type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default HotelForm