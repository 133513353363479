// Header.js
import React from 'react';
import Navigation from '../commons/Navigation';
import SearchHeader from './SearchHeader';
import '../commons/Header.scss'
import CartQuantity from '../commons/CartQuantity';
import LoginDropDown from './LoginDropDown';
import { handleLoginBtnClick } from '../commons/headerService';
import { useNavigate } from 'react-router';
import { useGlobalStore } from '../../context/GlobalContext';

function ListingHeader({ isSearchVisible = true, isHeaderTransparent = true, margin = true}) {
  const navigation = useNavigate()
  const { isUserLoggedIn } = useGlobalStore()
  return (
    < >
      <header style={margin ? { marginTop: '9rem' }: { marginTop: '6rem' }}>
        <div className="header fixed-header" style={!isHeaderTransparent ? {
          background: '#fff',
          borderBottom: '1px solid #eee',
          zIndex: 1060
        } : {}}  >
          <div className="container-fluid">
            <div className="flex">
              <div className="logo">
                <button className="btn-toggle"><img src="/images/desktop-hamburger.svg" alt="" /></button>
                <a onClick={()=> navigation('/') } >RESORTROVER</a>
                <Navigation />
              </div>
              <div className="side-menu">
                <button className="login-btn" onClick={() => handleLoginBtnClick()}>{isUserLoggedIn ? 'Logout': 'Login'}
                  <img className="login-img" src="/images/arrow-down-white.svg" style={{ color: 'black' }} alt="" />
                </button>
                <a >
                  <img src="/images/cart-white.svg" alt="" />
                </a>
                <CartQuantity />
                <LoginDropDown />
              </div>
            </div>
          </div>
        </div>
      </header >
      {isSearchVisible && <div>
        <SearchHeader />
      </div>
      }
    </ >
  );
}

export default ListingHeader;
