/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from 'react-router'
import { useGlobalStore } from '../../context/GlobalContext'

function LoginDropDown() {
  const navigation = useNavigate()
  const { isUserLoggedIn, setUserClear } = useGlobalStore()
  return (
    <nav className="dropdown-cs">
      <ul>
        <li>{isUserLoggedIn ? <a onClick={() => setUserClear()}>Logout</a>: <a onClick={() => navigation('/login')}>Login</a>}</li>
        {!isUserLoggedIn && <li><a onClick={() => navigation('/register')}>Sign Up</a></li>}
        <li><a onClick={() => navigation('/hotel-manager')}>Hotel Login</a></li>
        <li><a onClick={() => navigation('/my-bookings')}>List My Hotel</a></li>
      </ul>
    </nav>
  )
}

export default LoginDropDown