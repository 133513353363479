import React, { useEffect, useState } from 'react';
import FormContent from './FormContent'; // Assuming you named the form content component FormContent
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl, api, savedImages } from '../../config'
import { useGlobalStore } from '../../context/GlobalContext';
import Img from '../commons/Img';
import { AmenityIcons } from '../../shared/AmenityIcons';


function ListingSection() {
  const { setLoader } = useGlobalStore()
  const location = useLocation();
  const [hotelDetails, setHotelDetails] = useState([]);
  const searchQuery = new URLSearchParams(location.search).get('search');
  useEffect(() => {
    // Fetch data based on the search query parameter
    fetchData(searchQuery);
  }, []);

  const fetchData = async (searchQuery) => {
    try {
      // Fetch data using the search query parame
      setLoader(true)
      const url = !!searchQuery ? `/hotels/search-detail?query=${searchQuery}` : `/hotels/search`
      const res = await axios.get(`${baseUrl}${api}${url}`);
      setHotelDetails(res.data.hotels)
      // Handle the fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoader(false)
  };

  const navigate = useNavigate()
  return (
    <section className="listing-section">
      <div className="container-fluid">
        <div className="flex">
          <FormContent />
          <main>
            <h2>Hotels in and around  {hotelDetails.length > 0 ? hotelDetails?.[0]?.city : 'Phonix'} , {hotelDetails.length > 0 ? hotelDetails?.[0]?.state : 'Phonix'}</h2>
            <div className="all-list-section">
              {(hotelDetails || []).map((hotel, i) => (
                <a className="items" key={i} onClick={()=>{
                  navigate(`/details/${hotel.id}`)
                }}>
                  <div className="flex">
                    <div className="image">
                      <Img src={`${baseUrl}${savedImages}/${(hotel.images || '').split(',')[0]}`} alt="" />
                    </div>
                    <div className="main-details">
                      <div className="flex">
                        <div className="title-type">
                          <div className="type">{hotel.city}, {hotel.state}</div>
                          <div className="name">{hotel.name}</div>
                        </div>
                        <div className="favor">
                          <button type="button">
                            <Img src="/images/gray-heart-icon.1ca52980.svg" alt="" />
                          </button>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="rating">8.8</div>
                        <div className="comment">Very Good</div>
                        <div className="reviews">481 Reviews</div>
                      </div>
                      <div className="features">
                        <div className="flex">
                          {/* {(hotelDetails?.amenities || []).map((x) => {
                          const icon = AmenityIcons.find(y => y.value === x.icon)?.icon
                          return <div className="f1" key={x.name} title={x.name}>
                            {icon}
                            <span>{x.name}</span>
                          </div>
                        })} */}
                          <div className="f1">
                            <Img src="/images/bathroom.svg" alt="" />
                            <span>Restrooms</span>
                          </div>
                          <div className="f1">
                            <Img src="/images/towel.svg" alt="" />
                            <span>Towels</span>
                          </div>
                          <div className="f1">
                            <Img src="/images/spa.svg" alt="" />
                            <span>Spa</span>
                          </div>
                          <div className="f1">
                            <Img src="/images/hottub.svg" alt="" />
                            <span>Hot Tubs</span>
                          </div>
                        </div>
                      </div>
                      <div className="short-description">
                        <p>{hotel?.description}</p>
                      </div>
                      <div className="more-details">
                        {(hotel?.hotelPasses || []).map((hPass, i) => {
                          return (
                            <div className="info-main" key={i}>
                              <div className="info">
                                <h4>{hPass?.name}</h4>
                                <span>starting at</span>
                                <div className="price">{(hPass?.passes || []).find(x => x.passType === 'adult')?.price} <span className="cur">USD</span></div>
                              </div>
                              {hPass?.[i + 1] && <div className="info">
                                <h4>+1 More</h4>
                              </div>}
                              <div className="go-btn">
                                <div className="btn-submit">
                                  <Img src="/images/chevron-right.svg" alt="" />
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </main>
        </div>
      </div>
    </section>
  );
}

export default ListingSection;
