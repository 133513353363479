// ResortList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { api, baseUrl, savedImages } from '../../config';
import { useGlobalStore } from '../../context/GlobalContext';

function ResortList() {
  const [hotels, setHotels] = useState([]);
  const { setLoader } = useGlobalStore()

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        setLoader(true)
        const response = await axios.get(`${baseUrl}${api}/hotels/resortList`);
        setHotels(response.data);
      } catch (error) {
        console.error('Error fetching hotels:', error);
      }
      
      setLoader(false)
    };
    fetchHotels();
  }, []);

  const navigate = useNavigate()
  return (
    <section className="resort-list-home" aria-labelledby="carousel-heading">
      <div className="container">
        <h2 className="heading">Get away for the day</h2>
        <p className="tagline">Prices based on starting rates. Availability and pricing not guaranteed.</p>
        <div className="listing-of-resort">
          <div className="flex">
            {(hotels || []).map((hotel) => (
              <div key={hotel.id} className="item">
                <div className="main-resort">
                  <div className="img-slider">
                    <div className="items" style={{ backgroundRepeat: 'no-repeat' }}>
                      <img src={`${baseUrl}${savedImages}/${(hotel.images || '').split(',')[0]}`}
                        alt={`Resort `}
                        style={{ backgroundRepeat: 'no-repeat' }}
                      />
                    </div>
                  </div>
                  <a onClick={()=>{
                    navigate(`/details/${hotel.id}`)
                  }}>
                    <div className="details">
                      <div className="location">
                        <img src="/images/location-pin-orange.svg" alt="Location Pin" />
                        <span>{hotel.city}, {hotel.state}</span>
                      </div>
                      <div className="title">{hotel.name}</div>
                    </div>
                    <div className="small-details">
                      {/* Assuming you have rating and review data */}
                      <div className="main-rating">
                        <div className="flex">
                          <div className="rating">8.8</div>
                          <div className="text-sm">Very Good</div>
                          <div className="w-1 h-1 bg-rp-primary-new rounded-full ml-1.5"></div>
                          <div>481 reviews</div>
                        </div>
                      </div>
                      <div className="btn-booking">
                        <button className="btn-book">Book Now</button>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResortList;
