/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";

const Img = (props) => {
  const { fallback = null } = props;
  const [isBroken, setIsBroken] = useState(false);

  const handleError = () => {
    setIsBroken(true)
  }

  if (isBroken) {
    return fallback;
  }

  return <img onError={handleError} {...props} fallback={<div>🚧 image not found 🚧</div>} />
}

export default Img;