import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { baseUrl, api, savedImages } from '../../config'
import { useGlobalStore } from '../../context/GlobalContext';
import CategoryIcon from '@mui/icons-material/Category';

function HotelPassForm({ hotelId }) {
  const [passes, setPasses] = useState([]);
  const [isChildGuardianMandatory, setIsChildGuardianMandatory] = useState(true);
  const [maxPeople, setMaxPeople] = useState('');
  const [disableGeneralPass, setDisableGeneralPass] = useState(false)
  const { setLoader } = useGlobalStore()
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const { hotelPassId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (hotelPassId) {
      setLoader(true)
      axios.get(`${baseUrl}${api}/hotelPass/${hotelPassId}`)
        .then(response => {
          setName(response.data.name);
          setDescription(response.data.description);
          setIsChildGuardianMandatory(response.data.isChildGuardianMandatory);
          setMaxPeople(response.data.maxPeople);
          setPasses(response.data.passes || []);
        })
        .catch(error => {
          setError('Invalid form values. Please try again.');
        });

      setLoader(false)
    }
  }, []);

  const handleOptionChange = event => {
    setIsChildGuardianMandatory(event.target.value === 'true');
  };

  const addPass = (passType) => {
    setPasses(prevPasses => [...prevPasses, { passType: passType, price: '' }]);
    if (passes.length > 0) {
      setDisableGeneralPass(true)
    }
  };

  const cancelPass = (index) => {
    setPasses(prevPasses => prevPasses.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (passes.length == 0) {
      setError('Atleast One Pass Type Required')
      return
    }
    const formData = {
      id: hotelPassId ?? undefined,
      name: name,
      hotelId: hotelId,
      description: description,
      isChildGuardianMandatory: isChildGuardianMandatory,
      maxPeople: maxPeople,
      passes: passes  // Include passes in the form data
    };
    try {
      setLoader(true)
      if (hotelPassId) {
        await axios.put(`${baseUrl}${api}/hotelPass/${hotelPassId}`, formData)
      } else {
        await axios.post(`${baseUrl}${api}/hotelPass`, formData)
      }
    } catch (e) {
      setError('Invalid form values. Please try again.');
    }
    setLoader(false)
    navigate(`/hotel-manager/hotel-passes`);
  };

  return (
    <div>
      <div className="card m-0">
        <div className="card-body">
          <h5 className="card-title fw-semibold mb-4">Passes Form</h5>
          {error && <div className="alert alert-danger">{error}</div>}
          <form>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="mb-3">
              <label className="form-label">Max People</label>
              <input type="text" className="form-control" value={maxPeople} onChange={(e) => setMaxPeople(e.target.value)} />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              {!hotelPassId && <ReactQuill theme="snow" value={description} onChange={setDescription} />}
              {(description && !!hotelPassId) && <ReactQuill theme="snow" value={description} onChange={setDescription} />}
            </div>
            <div className="mb-3">
              <label className="form-label">Child Guardian</label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="true"
                  checked={isChildGuardianMandatory === true}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label" htmlFor="exampleRadios1">
                  Yes
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="false"
                  checked={isChildGuardianMandatory === false}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                  No
                </label>
              </div>
            </div>
            {/* Add buttons for adding passes */}
            <div className="mb-3">
              <button type="button" className="btn btn-primary" onClick={() => addPass('child')} disabled={passes.find(pass => pass.passType === 'child')}>Add Child Pass</button>
              <button type="button" className="btn btn-primary mx-2" onClick={() => addPass('adult')} disabled={passes.find(pass => pass.passType === 'adult')}>Add Adult Pass</button>
              <button type="button" className="btn btn-primary" onClick={() => addPass('infant')} disabled={passes.find(pass => pass.passType === 'infant')}>Add Infant Pass</button>
              <button type="button" className="btn btn-primary mx-2" onClick={() => addPass('general')} disabled={passes.find(pass => pass.passType === 'general') || passes.some(pass => pass.passType !== 'general')}>Add General Pass</button>
            </div>
            {/* Display added passes */}
          </form>
        </div>
      </div>
      {(passes || []).map((pass, index) => (<div className="card mt-2">
        <div className='card-body'>
          <div key={index} className="mb-3" style={{ marginTop: index === 0 ? '0' : '10px' }}>
            <button type="button" className="btn-close" aria-label="Close" onClick={() => cancelPass(index)} style={{ float: 'right' }}></button>
            <label className="form-label">Pass Type: {pass.passType}</label><br />
            <label className="form-label" style={{
              display: 'inline-flex'
            }}><span style={{
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: 4
            }}>Price: </span>
              <input type="text" placeholder='Price' className="form-control" value={pass.price} onChange={(e) => {
                const newPasses = [...passes];
                newPasses[index].price = e.target.value;
                setPasses(newPasses);
              }} />
            </label>
          </div>
        </div>
      </div>
      ))}
      <button type="button" className="btn btn-primary" onClick={handleSubmit} style={{ float: 'right' }}>Submit</button>
    </div>
  );
}


export default HotelPassForm