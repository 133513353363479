import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { baseUrl, api, savedImages } from '../../config'
import { useGlobalStore } from '../../context/GlobalContext';
import { validateEmail } from '../../services/validation';
import { toast } from 'react-toastify';
import { authConstants } from '../../constants/authConstant';

function HotelManagerForm() {
  const { setLoader, getAuthConfig } = useGlobalStore()
  const [error, setError] = useState(null)
  const navigate = useNavigate(null)
  const { id } = useParams();

  const [data, setData] = useState({
    username: '',
    email: '',
    password: ''
  })
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      id: id ?? undefined,
      ...data
    }
    try {

      if(!validateEmail(formData.email)) {
        setError('Please enter a valid email')
        return
      }
      setLoader(true)
      if (id) {
        await axios.put(`${baseUrl}${api}/admin/hotel-managers/${id}`, data, getAuthConfig(authConstants.adminToken))
      } else {
        await axios.post(`${baseUrl}${api}/admin/hotel-managers`, formData, getAuthConfig(authConstants.adminToken))
      }
      setLoader(false)
      toast.success("Hotel Manager saved successfully!")
      
      navigate('/admin/hotel-managers')
    } catch (e) {
      setError('Invalid form values. Please try again.'); // Set error message
    }

    setLoader(false)
  };

  const getData = async () => {
    if (id) {
      try {
        setLoader(true)
        const response = await axios.get(`${baseUrl}${api}/admin/hotel-managers/${id}`, getAuthConfig(authConstants.adminToken))
        setData({ username: response.data.username, email: response.data.email, password: '' });
      } catch (e) {
        setError('Invalid form values. Please try again.'); // Set error message
      }
      setLoader(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const handleFieldChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  return (
    <div className="card m-0">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">{id ? 'Edit Hotel Manager' : 'Add Hotel Manager'}</h5>
        {error && <div className="alert alert-danger">{error}</div>} {/* Display error message if error exists */}
        <form>
          <div className="mb-3">
            <label className="form-label">Username</label>
            <input type="text" className="form-control" autoComplete='off' name='username' defaultValue={data.username} onChange={handleFieldChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input type="email" className="form-control" autoComplete='off' name='email' defaultValue={data.email} onChange={handleFieldChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">{id ? 'Password' : 'New Password'}</label>
            <input type="password" className="form-control" name='password' autoComplete='off' onChange={handleFieldChange} />
            {id && <sub>Leave the field empty if you do not want to change existing password</sub>}
          </div>
          <button type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default HotelManagerForm