import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { api, baseUrl } from '../../config';
import { useGlobalStore } from '../../context/GlobalContext';
import { toast } from 'react-toastify';

function HotelCategoriesForm() {
  const { setLoader } = useGlobalStore()
  const { id } = useParams();
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    if (id) {
      try {
        
      } catch(error) {
        setError('Invalid form values. Please try again.');
      }
      setLoader(true)
      const response = await axios.get(`${baseUrl}${api}/hotelCategories/${id}`)
      setName(response.data.name);
      setLoader(false)
    }
  }
  useEffect(() => {
    getData()
  }, []);

  //   const handleOptionChange = event => {
  //     setIsChildGuardianMandatory(event.target.value === 'true');
  //   };

  //   const addPass = (passType) => {
  //     setPasses(prevPasses => [...prevPasses, { passType: passType, price: '' }]);
  //     if (passes.length > 0) {
  //       setDisableGeneralPass(true)
  //     }
  //   };

  //   const cancelPass = (index) => {
  //     setPasses(prevPasses => prevPasses.filter((_, i) => i !== index));
  //   };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      id: id ?? undefined,
      name: name,
    };
    setLoader(true)
    try {
      if (id) {
        await axios.put(`${baseUrl}${api}/hotelCategories/${id}`, formData)
      } else {
        await axios.post(`${baseUrl}${api}/hotelCategories`, formData)
      }
      toast.success("Hotel Category saved successfully!")
      setLoader(false)
      navigate(`/hotel-manager/hotel-categories`);
    }catch (e) {
      setLoader(false)
      setError('Invalid form values. Please try again.');
    }
  };

  return (
    <div>
      <div className="card m-0">
        <div className="card-body">
          <h5 className="card-title fw-semibold mb-4">Add Hotel Category </h5>
          {error && <div className="alert alert-danger">{error}</div>}
          <form>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
            </div>

          </form>
        </div>
      </div>

      <button type="button" className="btn btn-primary" onClick={handleSubmit} style={{ float: 'right' }}>Submit</button>
    </div>
  );
}


export default HotelCategoriesForm