
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import '../HotelManagerDashboard/HotelManagerDashboard.scss';
import { baseUrl, api } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalStore } from '../../context/GlobalContext';
import AlertDialog from '../../shared/AlertDialog';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { dataGridProps } from '../../constants/dataGridProps';
import { blue, red } from '@mui/material/colors';
import { AmenityIcons } from '../../shared/AmenityIcons';
import { authConstants } from '../../constants/authConstant';

function Amenities() {

  const confirmDelete = useRef()
  const { setLoader, getAuthConfig } = useGlobalStore()
  const navigate = useNavigate();
  const [amenitiesList, setAmenitiesList] = useState([]);
  const getAmenitiesList = async () => {
    try {
      setLoader(true)
      const res = await axios.get(`${baseUrl}${api}/admin/amenities`, getAuthConfig(authConstants.hotelManagerToken))
      setAmenitiesList((res?.data || []))
    } catch (e) {
      console.error(e)
    }
    
    setLoader(false)
  }

  const deleteAmenities = async (id) => {
    setLoader(true)
    await axios.delete(`${baseUrl}${api}/amenities/${id}`)
    getAmenitiesList()
    setLoader(false)
  }

  useEffect(() => {
    getAmenitiesList()
  }, [])

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'icon',
      headerName: 'Icon',
      flex: 1,
      renderCell: ({value}) => AmenityIcons.find(x => x.value === value || '')?.icon || []
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 100,
      flex: 1,
      getActions: ({row}) => {
        return [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-pen-to-square" color={blue[700]}/>}
            label="Edit"
            onClick={() => navigate(`/admin/amenities/edit/${row.id}`)}
          />,
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon="fa-solid fa-trash" color={red[700]} />}
            label="Delete"
            onClick={() => confirmDelete.current.handleClickOpen(row)}
          />,
        ]
      }
    },
  ];
  return (
    <div className="card w-100 m-0">
      <div className="card-body">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="card-title fw-semibold pb-1 pt-2">Amenities</h5>
          <button type="button" className="btn btn-dark m-1" onClick={() => {
            navigate('/admin/amenities/create')
          }}>
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </button>
        </div>
        <DataGrid
          rows={amenitiesList || []}
          columns={columns}
          {...dataGridProps}
        />
      </div>
      <AlertDialog ref={confirmDelete} action={deleteAmenities}/>
    </div>
  );
}

export default Amenities