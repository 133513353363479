/* eslint-disable jsx-a11y/anchor-is-valid */
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PolicyIcon from '@mui/icons-material/Policy';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CategoryIcon from '@mui/icons-material/Category';
import BallotIcon from '@mui/icons-material/Ballot';
import HomeIcon from '@mui/icons-material/Home';
import React from 'react'
import { useNavigate } from 'react-router'

function HotelManagerSideBar() {
  const navigate = useNavigate()

  const menuItems = [
    {
      icon: <HomeIcon />,
      title: 'Hotel Profile',
      path: '/hotel-manager/profile'
    },
    // {
    //   icon: <CategoryIcon />,
    //   title: 'Categories',
    //   path: '/hotel-manager/hotel-categories'
    // },
    {
      icon: <BallotIcon />,
      title: 'Amenities',
      path: '/hotel-manager/hotel-amenities'
    },
    {
      icon: <BookOnlineIcon />,
      title: 'Bookings',
      path: '/hotel-manager/hotel-bookings'
    },
    {
      icon: <ConfirmationNumberIcon />,
      title: 'Passes',
      path: '/hotel-manager/hotel-passes'
    },
    {
      icon: <PolicyIcon />,
      title: 'Cancel Policy',
      path: '/hotel-manager/cancel-policy'
    },
    {
      icon: <HelpCenterIcon />,
      title: 'How it Works',
      path: '/hotel-manager/how-it-works'
    },
    {
      icon: <AccessTimeIcon />,
      title: 'Operating Hours',
      path: '/hotel-manager/timings'
    }
  ]
  return (
    <aside className="left-sidebar">
      <div>
        <nav className="sidebar-nav scroll-sidebar" style={{height: '100vh'}} data-simplebar="">
          <ul id="sidebarnav">
            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span className="hide-menu">Hotel Manager</span>
            </li>
            {menuItems.map(menuItem => <li key={menuItem.title} className="sidebar-item">
              <a className="sidebar-link" onClick={()=>navigate(menuItem.path)}  aria-expanded="false">
                <span>
                  {menuItem.icon}
                </span>
                <span className="hide-menu">{menuItem.title}</span>
              </a>
            </li>)}
          </ul>
        </nav>
      </div>
    </aside>
  )
}

export default HotelManagerSideBar