import React from 'react';
import HotelManagerPrivateRoutes from './HotelManagerPrivateRoutes';
import HotelManagerPublicRoutes from './HotelManagerPublicRoutes';
import { useGlobalStore } from '../context/GlobalContext';
import HotelManagerLayout from './commons/HotelManagerLayout';

const HotelManagerModule = () => {
  const { isHotelManagerLoggedIn } = useGlobalStore();


  return isHotelManagerLoggedIn ? <HotelManagerLayout><HotelManagerPrivateRoutes /></HotelManagerLayout> : <HotelManagerPublicRoutes />;
};

export default HotelManagerModule;
